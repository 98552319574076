import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

export function Switches({ autoFlow, setAutoFlow, titles, setTitles, verticalDisabled, vertical, setVertical }) {
    const { t } = useTranslation();

    return (
        <Grid sx={{ mb: -4 }} xs={4} md={4} item justifyContent="center">
            <FormControlLabel
                sx={{ mt: -2 }}
                control={<Switch checked={autoFlow} onChange={(e) => setAutoFlow(e.target.checked)} />}
                label={t("autoFlow")}
            />
            <FormControlLabel
                sx={{ mt: -2 }}
                control={<Switch checked={titles} onChange={(e) => setTitles(e.target.checked)} />}
                label="Titles"
            />
            <FormControlLabel
                sx={{ mt: -2 }}
                control={
                    <Switch
                        color="secondary"
                        disabled={verticalDisabled}
                        checked={vertical}
                        onChange={(e) => setVertical(e.target.checked)}
                    />
                }
                label={t("vertical")}
            />
        </Grid>
    );
}
