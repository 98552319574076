import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../UI/Copyright";
import { API } from "../App";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Forgot = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [color, setColor] = useState("success");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        setMessage("");
        try {
            const response = await fetch(`${API}/forgot`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: data.get("email"),
                }),
            });
            const json = await response.json();
            if (!response.ok) {
                setColor("error");
            } else {
                setColor("success");
            }
            setMessage(json.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("forgot")}
                </Typography>
                <Grid container>
                    <Grid item xs>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t("email")}
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <br></br>
                            <Grid sx={{ mt: 3, mb: 2 }} container justifyContent="center">
                                {loading && <CircularProgress color="primary" />}
                            </Grid>
                            {message && (
                                <Typography color={color} variant="body2">
                                    {message}
                                </Typography>
                            )}
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                {t("reset")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Copyright />
        </Container>
    );
};

export default Forgot;
