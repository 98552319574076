import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import Audiotrack from "@mui/icons-material/Audiotrack";
import { API, UserContext } from "../App";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

export default function MusicUpload({ onUpload }) {
    const [musicError, setMusicError] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useContext(UserContext);
    const { t } = useTranslation();

    const musicHandler = async (music) => {
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }
        let formData = new FormData();
        formData.append("music", music);
        if (music?.size > 20000000) {
            setMusicError(true);
            return;
        }
        setLoading(true);
        const response = await fetch(API + "/music", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${user?.token}`,
            },
            body: formData,
        });
        setLoading(false);
        if (response.ok) {
            const data = await response.json();
            setMusicError(false);
            if (onUpload) {
                onUpload(data.music);
            }
        } else {
            setMusicError(true);
        }
    };

    return (
        <>
            <Button startIcon={<Audiotrack />} variant="outlined" color="secondary" component="label">
                {t("Upload Music")}
                <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => {
                        musicHandler(event.target.files[0]);
                    }}
                />
                {loading ? <CircularProgress style={{ position: "absolute" }} size={20} /> : null}
            </Button>
            {musicError && (
                <Typography sx={{ mt: 1 }} variant="body2">
                    {"Please MP3, max 20MB"}
                </Typography>
            )}
        </>
    );
}
