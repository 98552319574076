import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useMediaPredicate } from "react-media-hook";

const Video = ({ src, vertical }) => {
    const videoRef = useRef();
    const big = useMediaPredicate("(min-width: 700px)") ? 1.5 : 1;

    useEffect(() => {
        videoRef.current?.load();
    }, [src]);

    return (
        <Box
            sx={{
                ml: 1,
                width: vertical ? 240 * big : 320 * big,
                height: vertical ? 320 * big : 240 * big,
                backgroundColor: "gray",
                "&:hover": {
                    backgroundColor: "darkgray",
                    opacity: [0.9, 0.8, 0.7],
                },
            }}
        >
            <video
                ref={videoRef}
                key={src}
                width={vertical ? 240 * big : 320 * big}
                height={vertical ? 320 * big : 240 * big}
                autoPlay
                controls
                src={src}
            />
        </Box>
    );
};

export default Video;
