import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Like } from "../UI/Like";
import { Share } from "../UI/Share";
import { useState } from "react";
import { API } from "../App";
import { Link } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Avatar, IconButton } from "@mui/material";
import ReactGA from "react-ga4";
import { Featured } from "../UI/Featured";
import { PlayArrow } from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";

export function StoryCard({
    card,
    isPlaying,
    onPlayClick,
    admin,
    deleteStory,
    publishStory,
    markAppropriate,
    markUploaded,
    markFeatured,
    openUser,
}) {
    const [liked, setLiked] = useState(false);
    const [featured, setFeatured] = useState(card.featured);

    const sendLike = () => {
        fetch(API + "/story/like", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: card._id }),
        }).catch((err) => {});
        ReactGA.event({
            category: "user",
            action: "like",
            value: 0,
        });
    };

    function isAsian(str) {
        const regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uac00-\ud7af]/;
        return regex.test(str);
    }

    return (
        <Grid item key={card.description} xs={12} sm={6} md={3}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <Grid container justifyContent="right">
                    {admin && (
                        <Featured
                            featured={featured}
                            toggleFeatured={() => {
                                setFeatured(!featured);
                                if (markFeatured) markFeatured(card._id, !featured);
                            }}
                        />
                    )}
                    {admin && (
                        <Typography sx={{ px: 2, pt: 2 }} align="right" color="gray" variant="body2">
                            {new Date(
                                card.script.map((slide) => slide.audio?.duration ?? 0).reduce((a, b) => a + b, 0) * 1000
                            )
                                .toTimeString()
                                .slice(3, 8)}
                        </Typography>
                    )}
                    {admin && card?.user && (
                        <IconButton size="small" onClick={() => openUser(card.user)} color="inherit">
                            <Avatar sx={{ bgcolor: "#" + card?.user?.slice(-6), width: 28, height: 28 }}>
                                {card?.user?.slice(-1)}
                            </Avatar>
                        </IconButton>
                    )}
                    {admin && markUploaded && (
                        <IconButton onClick={() => !card.uploaded && markUploaded(card._id)}>
                            <YouTubeIcon sx={{ color: card.uploaded ? "red" : "gray", mt: 0 }} />
                        </IconButton>
                    )}
                    {card.inappropriate && markAppropriate && (
                        <IconButton onClick={() => markAppropriate && markAppropriate(card._id)}>
                            <FlagIcon sx={{ color: "red", mt: 0 }} />
                        </IconButton>
                    )}
                    {card.privateStory && !card.inappropriate && publishStory && (
                        <Button size="small" sx={{ pt: 2 }} onClick={() => publishStory(card._id)}>
                            Publish
                        </Button>
                    )}
                    <Typography sx={{ px: 2, pt: 2 }} align="right" color="gray" variant="body2">
                        {new Date(card.createdAt).toLocaleDateString()}
                    </Typography>
                </Grid>
                <div style={{ position: "relative" }}>
                    {isPlaying && card.video && (
                        <video
                            src={API + card.video}
                            controls
                            autoPlay
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                zIndex: 1,
                            }}
                        />
                    )}
                    <CardMedia
                        component={card.script[0].image?.substr(-4) === ".mp4" ? "video" : "img"}
                        height="200"
                        width="320"
                        sx={{
                            mt: 1,
                            zIndex: 0,
                        }}
                        src={API + card.script[0].image}
                        autoPlay
                        muted
                    />
                    {!isPlaying && (
                        <IconButton
                            href={card.vertical ? API + card.video : undefined}
                            onClick={() => {
                                if (!card.vertical) {
                                    onPlayClick(card._id);
                                }
                            }}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 2,
                                borderRadius: "50%",
                                width: "60px",
                                height: "60px",
                                padding: 0,
                                opacity: 0.7,
                            }}
                        >
                            <PlayArrow sx={{ fontSize: "40px" }} />
                        </IconButton>
                    )}
                </div>
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {card.setting}
                    </Typography>
                    <Typography>{card.description.substring(0, isAsian(card.description) ? 100 : 200)}</Typography>
                </CardContent>
                <CardActions>
                    <Like liked={liked} setLiked={setLiked} sendLike={sendLike} />
                    <IconButton component={Link} to={"/comments/" + (card.slug ?? card._id)}>
                        <CommentIcon />
                    </IconButton>
                    <Grid container justify="flex-end" alignItems="flex-end">
                        <Share
                            setting={card.setting}
                            description={card.description}
                            url={"https://mangatv.shop/story/" + (card.slug ?? card._id)}
                        />
                    </Grid>
                    <Button component={Link} to={"/story/" + card._id} size="small">
                        Open
                    </Button>
                    <Button component={Link} to={"/webtoon/" + (card.slug ?? card._id)} size="small">
                        Webtoon
                    </Button>
                    {admin && (
                        <Button size="small" onClick={() => deleteStory(card._id)}>
                            Delete
                        </Button>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
}
