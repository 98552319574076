import { useContext } from "react";
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { Container } from "@mui/material";
import { UserContext } from "../App";
import { Typography } from "@mui/material/";
import Stories from "./Stories";
import { FREE_MINUTES } from "../payment/Buy";
import ShareIcon from "@mui/icons-material/Share";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import InfoIcon from "@mui/icons-material/Info";
import MusicUpload from "../UI/MusicUpload";

const Account = () => {
    const { t } = useTranslation();
    const user = useContext(UserContext);

    return (
        <>
            <Helmet>
                <title>Manga TV - Account</title>
            </Helmet>
            <Container sx={{ py: 3 }} maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">{user?.firstName}</Typography>
                                <Typography variant="h4">{user?.lastName}</Typography>
                                <Typography variant="h6">{user?.email}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {user?.referral && (
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h4">{t("referral")}</Typography>
                                    <Typography variant="h2">{user?.referral}</Typography>
                                </CardContent>
                                <IconButton
                                    onClick={async () => {
                                        if (!navigator.share) return;
                                        navigator
                                            .share({
                                                title: "Signup Link",
                                                text: t("manga"),
                                                url: "https://mangatv.shop/signup/" + user?.referral,
                                            })
                                            .catch();
                                    }}
                                >
                                    <ShareIcon />
                                    <Typography sx={{ mx: 2 }} variant="body2">
                                        {t("share")}
                                    </Typography>
                                </IconButton>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                background:
                                    user?.plan === "gold"
                                        ? "gold"
                                        : user?.plan === "silver"
                                        ? "silver"
                                        : user?.plan === "bronze"
                                        ? "#CD7F32"
                                        : "",
                            }}
                        >
                            <CardContent>
                                <Typography variant="h4">{t("plan")}</Typography>
                                <Typography variant="h2">{user?.plan?.toUpperCase() ?? "FREE"}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">{t("balance")}</Typography>
                                <Typography variant="h2">{balance(user)}</Typography>
                                {!user?.plan && !user?.locked && (
                                    <Grid container justifyContent="left">
                                        <InfoIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="body2">{t("free_minutes")}</Typography>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={10}>
                        <MusicUpload />
                    </Grid>
                </Grid>
            </Container>
            {user && <Stories nodedup my={true} admin={user?.plan || user?.admin} />}
        </>
    );
};

export const balance = (user) => {
    switch (user?.plan) {
        case "gold":
            return Math.max(1500 - (user?.spent ?? 0), 0).toFixed(2) + "~min";
        case "silver":
            return Math.max(300 - (user?.spent ?? 0), 0).toFixed(2) + "~min";
        case "bronze":
            return Math.max(100 - (user?.spent ?? 0), 0).toFixed(2) + "~min";
        default:
            return (user?.locked ? 0 : Math.max(FREE_MINUTES - (user?.spent ?? 0), 0).toFixed(2)) + "~min";
    }
};

export default Account;
