import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import { FREE_MINUTES } from "./payment/Buy";

const resources = {
    en: {
        translation: {
            welcome:
                "Discover stories created by our community. Just provide a prompt, and AI will generate a full video story for you.",
            inspiration: "Get inspired!",
            buy: "Buy package",
            try: "Try for free",
            ready: "🦄 Video is ready!",
            paid: "This is a paid plan feature",
            summary: "Order summary",
            minutes: "100 minutes of video included",
            minutes2: "300 minutes of video included",
            minutes3: "1500 minutes of video included",
            review: "Review your order",
            checkout: "Checkout",

            pricing: "Pricing",
            onetime: "One-time payment",
            select: "No subscription required – just make a one-time payment. Purchase as many minutes as you need.",
            title1: "Free",
            body11: `${FREE_MINUTES} minutes of video included`,
            body12: "10 slides in the story",
            body13: "Standard Voices",
            body14: "Public content",
            body15: "Sign up for free",
            body16: "Basic Styles",
            body26: "Custom Styles",
            title2: "Silver",
            subheader: "Most popular",
            title21: "Bronze",
            body211: "1.5 hours of video included",
            body21: "5 hours of video included",
            body22: "20 slides in the story",
            body23: "WaveNet Voices",
            body24: "Royalty free videos, PDF",
            body25: "Purchase",
            title3: "Gold",
            body31: "25 hours of video included",
            body32: "∞ slides",
            body33: "OpenAI/ElevenLabs Voices",
            body34: "Auto YouTube upload",

            sure: "Are you sure?",
            undone: "Your will not be able to undone this action",
            yes: "Yes",
            cancel: "Cancel",
            delete: "Delete",
            repaint: "Repaint",
            blurred: "If you always have blurred image, try remove stop words or explicit content",
            split: "Split the cell?",
            open: "Open",
            watch: "Watch",
            deleteStory: "Delete this story?",
            storyDeleted: "Story has been deleted.",
            mark: "Mark this story as appropriate?",
            markUploaded: "Mark this story as already uploaded?",
            marked: "Story marked as good",
            deleteSlide: "Delete this slide?",
            publish: "Publish this private story?",
            published: "Story has been published",
            done: "Done",
            error: "Error",

            title: "Title",
            details: "Details",
            category: "Category",
            image: "Image",
            upload: "Upload to Youtube?",
            upload_tiktok: "Upload to TikTok?",
            profile: "My Profile",

            description: "Description of the story",
            descriptionHint:
                "Better start with 'Create/Tell me/Write a story about ...' Or just start a story and set below option. And please avoid explicit content😏",
            lang: "Language",
            voice: "Voice",
            setting: "Visual Setting",
            music: "Music",
            autoFlow: "Auto",
            vertical: "Vertical",
            include: "Include description into story",
            model: "Text Model",
            imageModel: "Image Model",
            temperature: "Temperature",
            hint: "You can find video in My Account after it is ready",
            video: "Video",
            generate: "Generate",
            pictures: "Pictures",
            continue: "Continue Story",

            none: "None",
            infinite: "Infinite",
            update: "Update",
            restart: "Update is available. Please restart application.",

            search: "Search",
            searchStories: "Search stories",
            home: "Home",
            new: "Create",
            dashboard: "Dashboard",
            private: "Private stories",

            account: "My Account",
            signin: "Sign In",
            signup: "Sign Up",
            logout: "Logout",
            forgot: "Forgot password?",
            reset: "Reset password",
            set: "Set password",
            newpass: "New Password",
            retype: "Retype password",
            dont: "Don't have an account? Sign Up",
            remember: "Remember me",
            password: "Password",
            email: "Email address",
            first: "First Name",
            last: "Last Name",
            already: "Already have an account? Sign in",
            marketing: "I want to receive inspiration, marketing promotions and updates via email",

            plan: "Plan",
            balance: "Remaining Video Minutes",
            referral: "Referral Code",
            free_minutes: "2 free minutes every day",
            share: "Share your referral code to get free 20 minutes",
            manga: "Manga TV AI Comics Generator with 20% discount! ❤️",
            over: "Your minutes are over! Please buy more minutes or wait for the next day ❤️",
            payment: "Payment needed",
            featured: "Editor's pick",
            eligible: "Availability of the free plan depends on the server load",
            hint2: "After story is done, we suggest you repaint bad pictures, remove wrong slides and press Video button again 🤗",
            uploadImage: "Upload",
            custom: "Custom visuals",
            customText: "Here you can create custom visual settings for your stories. Click + button to add new one",
            randomize: "Randomize",
            effect: "Video Effect",
            myVoices: "My Voices",
        },
    },
    ru: {
        translation: {
            welcome: "Откройте для себя истории сообщества. ИИ создаст для вас видеоисторию по единственной подсказке",
            inspiration: "Найдите идею!",
            buy: "Купить пакет",
            try: "Попробовать",
            ready: "🦄 Видео готово!",
            paid: "Это функция платного плана",

            pricing: "Цены",
            onetime: "Разовый платеж",
            select: "Нет необходимости в подписке - просто сделайте одноразовый платеж. Приобретайте столько минут, сколько вам нужно.",
            title1: "Бесплатно",
            body11: `${FREE_MINUTES} минут видео включено`,
            body12: "10 слайдов в истории",
            body16: "Базовые стили",
            body26: "Пользовательские стили",
            body13: "Стандартные голоса",
            body14: "Публичный контент",
            body15: "Попробовать бесплатно",
            subheader: "Самый популярный",
            body211: "1.5 часа видео включено",
            body21: "5 часов видео включено",
            body22: "20 слайдов в истории",
            body23: "Голоса WaveNet",
            body24: "Авторские права, PDF",
            body25: "Купить",
            body31: "25 часов видео включено",
            body32: "∞ слайдов",
            body33: "Голоса OpenAI/ElevenLabs",
            body34: "Автозагрузка на YouTube",

            sure: "Вы уверены?",
            undone: "Вы не сможете отменить это действие",
            yes: "Да",
            cancel: "Отмена",
            delete: "Удалить",
            repaint: "Перерисовать",
            blurred: "Если у вас всегда размытая картинка, попробуйте убрать стоп-слова или плохой контент",
            split: "Разделить ячейку?",
            open: "Открыть",
            watch: "Смотреть",
            deleteStory: "Удалить эту историю?",
            storyDeleted: "История была удалена.",
            mark: "Отметить эту историю как подходящую?",
            markUploaded: "Отметить эту историю как загруженную?",
            marked: "История отмечена как хорошая",
            deleteSlide: "Удалить этот слайд?",
            publish: "Опубликовать эту историю?",
            published: "История опубликована",
            done: "Готово",
            error: "Ошибка",

            title: "Заголовок",
            details: "Подробности",
            category: "Категория",
            image: "Картинка",
            upload: "Загрузить на Youtube?",
            upload_tiktok: "Загрузить на TikTok?",
            profile: "Мой профиль",

            description: "Описание истории",
            descriptionHint:
                "Лучше начать с 'Создай/Расскажи/Напиши историю про ...' Или просто начните историю и установите флажок внизу. И пожалуйста, избегайте непристойного контента😏",
            lang: "Язык",
            voice: "Голос",
            setting: "Визуальный сеттинг",
            music: "Музыка",
            include: "Включить описание в историю",
            model: "Модель текста",
            imageModel: "Модель картинок",
            temperature: "Температура",
            hint: "Готовое видео вы найдете в Мой аккаунт",
            video: "Видео",
            generate: "Создать",
            pictures: "Картинки",
            continue: "Продолжить историю",

            search: "Поиск",
            searchStories: "Поиск историй",
            home: "Домой",
            new: "Создать",
            dashboard: "Аналитика",
            private: "Приватные истории",

            account: "Мой аккаунт",
            signin: "Войти",
            logout: "Выйти",
            signup: "Зарегистрироваться",
            forgot: "Забыли пароль?",
            reset: "Сбросить пароль",
            set: "Установить пароль",
            newpass: "Новый пароль",
            retype: "Повторите пароль",
            dont: "Зарегистрируйтесь",
            remember: "Запомнить меня",
            password: "Пароль",
            email: "Email",
            first: "Имя",
            last: "Фамилия",
            already: "Уже есть аккаунт? Войти",
            marketing: "Я хочу получать новости и обновления по электронной почте.",

            plan: "План",
            balance: "Оставшееся время видео",
            referral: "Реферальный код",
            free_minutes: "2 минуты ежедневно",
            share: "Поделитесь своим реферальным кодом и получите бесплатные 20 минут",
            manga: "Manga TV AI Comics Generator с 20% скидкой! ❤️",
            over: "Ваши минуты закончились! Пожалуйста, купите больше минут или подождите следующего дня ❤️",
            payment: "Необходим платеж",
            featured: "Выбор редакции",
            eligible: "Бесплатный план доступен в зависимости от загрузки сервера",
            hint2: "После того, как видео будет готово, мы предлагаем перерисовать плохие картинки, исправить тексты, если это необходимо, удалить неправильные слайды и снова нажать кнопку Видео 🤗",
            uploadImage: "Загрузить",
            custom: "Свои стили",
            customText: "Здесь вы можете создать стили для своих историй. Нажмите кнопку +, чтобы добавить",
            randomize: "Случайно",
            effect: "Видеоэффект",
            myVoices: "Мои голоса",
        },
    },
    cz: {
        translation: {
            welcome:
                "Objevujte příběhy vytvořené naší komunitou. Stačí zadat podnět, a umělá inteligence pro vás vygeneruje kompletní video příběh.",
            inspiration: "Získejte inspiraci!",
            buy: "Koupit balíček",
            try: "Vyzkoušet",
            ready: "🦄 Video je připraveno!",
            paid: "Tato funkce je dostupná pouze v placeném balíčku",

            pricing: "Ceny",
            onetime: "Jednorázově",
            select: "Žádná předplatné není nutná - stačí provést jednorázovou platbu. Kupte si tolik minut, kolik potřebujete.",
            title1: "Zdarma",
            body11: `${FREE_MINUTES} minut videa zahrnuto`,
            body12: "10 snímků v příběhu",
            body16: "Základní styly",
            body26: "Vlastní styly",
            body13: "Hlas Standard",
            body14: "Veřejný obsah",
            body15: "Vyzkoušet zdarma",
            subheader: "Nejpopulárnější",
            body211: "1.5 hodina videa včetně",
            body21: "5 hodin videa zahrnuto",
            body22: "20 snímků v příběhu",
            body23: "Hlas WaveNet",
            body24: "Autorská práva, PDF",
            body25: "Koupit",
            body31: "25 hodin videa zahrnuto",
            body32: "∞ snímků",
            body33: "Hlas OpenAI/ElevenLabs",
            body34: "Automatické nahrávání na YouTube",

            sure: "Jste si jistý?",
            undone: "Tuto akci nebudete moci vrátit zpět",
            yes: "Ano",
            cancel: "Zrušit",
            delete: "Odstranit",
            repaint: "Překreslit",
            blurred: "Pokud máte vždy rozostřený obraz, zkuste odstranit stop-slova nebo špatný obsah",
            split: "Rozdělit buňku?",
            open: "Otevřít",
            watch: "Sledovat",
            deleteStory: "Odstranit tento příběh?",
            deleteSlide: "Odstranit tento snímek?",
            publish: "Publikovat tento příběh?",

            title: "Název",
            details: "Podrobnosti",
            category: "Kategorie",
            image: "Obrázek",
            upload: "Nahrát na Youtube?",
            profile: "Můj profil",

            description: "Popis události",
            descriptionHint:
                "Nejlepší je začít s 'Vytvořte / Vyprávějte / Napište příběh o ...' nebo jednoduše začněte příběh a nastavte parametry níže. A prosím, vyhněte se nevhodnému obsahu😏",
            lang: "Jazyk",
            voice: "Hlas",
            setting: "Vizuální nastavení",
            music: "Hudba",
            include: "Vložit popis do příběhu",
            model: "Model textu",
            imageModel: "Model obrázku",
            temperature: "Teplota",
            hint: "Video najdete v Můj účet po dokončení",
            video: "Video",
            generate: "Vytvořit",
            pictures: "Obrázky",
            continue: "Pokračovat vytvářením",

            search: "Hledat",
            searchStories: "Hledat příběhy",
            home: "Domů",
            new: "Vytvořit",
            dashboard: "Analytika",

            account: "Můj účet",
            signin: "Přihlášení",
            logout: "Odhlášení",
            signup: "Registrace",
            forgot: "Zapomněli jste heslo?",
            reset: "Obnovit heslo",
            set: "Nastavit heslo",
            newpass: "Nové heslo",
            retype: "Zopakujte heslo",
            dont: "Zaregistrujte se",
            remember: "Zapamatovat si mě",
            password: "Heslo",
            email: "Email",
            first: "Jméno",
            last: "Příjmení",
            already: "Už máte účet? Přihlásit se",
            marketing: "Chci dostávat novinky a aktualizace e-mailem.",

            plan: "Plán",
            balance: "Zbývající videominuty",
            referral: "Odkazový kód",
            free_minutes: "2 minuty denně",
            share: "Sdílejte svůj odkazový kód a získejte 20 minut zdarma",
            manga: "Manga TV AI Comics Generator s 20% slevou! ❤️",
            over: "Vaše minuty došly! Prosím, kupte si více minut nebo počkejte na další den ❤️",
            payment: "Je potřeba platba",
            featured: "Doporučené",
            eligible: "Bezplatný plán je k dispozici v závislosti na vytížení serveru",
            hint2: "Po dokončení videa vám nabízíme překreslit špatné obrázky, opravit texty, pokud je to nutné, odstranit nesprávné snímky a znovu stisknout tlačítko Video 🤗",
            uploadImage: "Nahrát",
            custom: "Vlastní styly",
            customText: "Zde můžete vytvořit vlastní styly pro své příběhy. Klikněte na tlačítko + pro přidání nového",
            randomize: "Náhodně",
            effect: "Video efekt",
            myVoices: "Moje hlasy",
        },
    },
    de: {
        translation: {
            welcome:
                "Entdecken Sie Geschichten, die von unserer Gemeinschaft erstellt wurden. Geben Sie einfach einen Anstoß, und die KI generiert eine vollständige Video-Geschichte für Sie.",
            inspiration: "Lass dich inspirieren!",
            buy: "Paket kaufen",
            try: "Versuchen",
            ready: "🦄 Video ist fertig!",
            paid: "Diese Funktion ist nur in einem kostenpflichtigen Paket verfügbar",

            pricing: "Preise",
            onetime: "Einmalig",
            select: "Kein Abonnement erforderlich – machen Sie einfach eine Einmalzahlung. Kaufen Sie so viele Minuten, wie Sie benötigen.",
            title1: "Kostenlos",
            body11: `${FREE_MINUTES} Minuten Video enthalten`,
            body12: "10 Bilder im Story",
            body16: "Grundlegende Stile",
            body26: "Benutzerdefinierte Stile",
            body13: "Standart-Stimme",
            body14: "Öffentlicher Inhalt",
            body15: "Kostenlos testen",
            subheader: "Am beliebtesten",
            body211: "1.5 Stunde Video enthalten",
            body21: "5 Stunden Video enthalten",
            body22: "20 Bilder im Story",
            body23: "WaveNet-Stimme",
            body24: "Urheberrechte, PDF",
            body25: "Kaufen",
            body31: "25 Stunden Video enthalten",
            body32: "∞ Bilder",
            body33: "OpenAI/ElevenLabs-Stimme",
            body34: "Automatisches Hochladen auf YouTube",

            sure: "Bist du sicher?",
            undone: "Sie können diese Aktion nicht rückgängig machen",
            yes: "Ja",
            cancel: "Abbrechen",
            delete: "Löschen",
            repaint: "Neu zeichnen",
            blurred:
                "Wenn Sie immer ein unscharfes Bild haben, versuchen Sie, Stoppwörter oder schlechten Inhalt zu entfernen",
            split: "Zelle teilen?",
            open: "Öffnen",
            watch: "Ansehen",
            deleteStory: "Diesen Story löschen?",
            deleteSlide: "Dieses Bild löschen?",
            publish: "Diesen Story veröffentlichen?",

            title: "Titel",
            details: "Einzelheiten",
            category: "Kategorie",
            image: "Bild",
            upload: "Auf Youtube hochladen?",
            profile: "Mein Profil",

            description: "Beschreibung der Veranstaltung",
            descriptionHint:
                "Es ist am besten, mit 'Erstellen / Erzählen / Schreiben Sie eine Geschichte über ...' zu beginnen oder beginnen Sie einfach mit der Geschichte und stellen Sie die Parameter unten ein. Und bitte vermeiden Sie unangemessene Inhalte😏",
            lang: "Sprache",
            voice: "Stimme",
            setting: "Visuelle Einstellungen",
            music: "Musik",
            include: "Beschreibung in die Story einfügen",
            model: "Modell",
            imageModel: "Bildmodell",
            temperature: "Temperatur",
            hint: "Video finden Sie in Mein Konto nach Abschluss",
            video: "Video",
            generate: "Erstellen",
            pictures: "Bilder",
            continue: "Weiter erstellen",

            search: "Suche",
            searchStories: "Suche nach Stories",
            home: "Startseite",
            new: "Erstellen",
            dashboard: "Analytik",

            account: "Mein Konto",
            signin: "Anmelden",
            logout: "Abmelden",
            signup: "Registrieren",
            forgot: "Passwort vergessen?",
            reset: "Passwort zurücksetzen",
            set: "Passwort festlegen",
            newpass: "Neues Passwort",
            retype: "Passwort wiederholen",
            dont: "Registrieren Sie sich",
            remember: "Angemeldet bleiben",
            password: "Passwort",
            email: "Email",
            first: "Vorname",
            last: "Nachname",
            already: "Sie haben bereits ein Konto? Anmelden",
            marketing: "Ich möchte E-Mails mit Neuigkeiten, Werbeangeboten und Updates erhalten.",

            plan: "Plan",
            balance: "Verbleibende Videominuten",
            referral: "Empfehlungscode",
            free_minutes: "2 Minuten pro Tag",
            share: "Teilen Sie Ihren Empfehlungscode und erhalten Sie 20 Minuten kostenlos",
            manga: "Manga TV AI Comics Generator mit 20% Rabatt! ❤️",
            over: "Ihre Minuten sind abgelaufen! Bitte kaufen Sie mehr Minuten oder warten Sie auf den nächsten Tag ❤️",
            payment: "Zahlung erforderlich",
            featured: "Empfohlen",
            eligible: "Kostenloser Plan ist je nach Serverauslastung verfügbar",
            hint2: "Nachdem das Video fertig ist, bieten wir Ihnen an, schlechte Bilder neu zu zeichnen, Texte zu korrigieren, falls erforderlich, falsche Folien zu entfernen und die Schaltfläche Video erneut zu drücken 🤗",
            uploadImage: "Hochladen",
            custom: "Benutzerdefinierte Stile",
            customText:
                "Hier können Sie benutzerdefinierte Stile für Ihre Geschichten erstellen. Klicken Sie auf die Schaltfläche +, um einen neuen hinzuzufügen",
            randomize: "Zufällig",
            effect: "Videoeffekt",
            myVoices: "Meine Stimmen",
        },
    },
    it: {
        translation: {
            welcome: `Scopri storie create dalla nostra comunità. Basta fornire uno spunto, e l'IA genererà una storia completa in video per te.`,
            inspiration: "Fatti ispirare!",
            buy: "Acquista pacchetto",
            try: "Prova",
            ready: "🦄 Video pronto!",
            paid: "Questa funzione è disponibile solo in un pacchetto a pagamento",

            pricing: "Prezzi",
            onetime: "Una tantum",
            select: "Nessun abbonamento richiesto, basta effettuare un pagamento una tantum. Acquista tutte le minuti di cui hai bisogno.",
            title1: "Gratis",
            body11: `${FREE_MINUTES} minuti di video inclusi`,
            body12: "10 immagini nella storia",
            body16: "Stili di base",
            body26: "Stili personalizzati",
            body13: "Voce Standard",
            body14: "Contenuto pubblico",
            body15: "Prova gratis",
            subheader: "Più popolare",
            body211: "1.5 ora di video inclusa",
            body21: "5 ore di video inclusi",
            body22: "20 immagini nella storia",
            body23: "Voce WaveNet",
            body24: "Diritti d'autore, PDF",
            body25: "Acquista",
            body31: "25 ore di video inclusi",
            body32: "∞ immagini",
            body33: "Voce OpenAI/ElevenLabs",
            body34: "Caricamento automatico su YouTube",

            sure: "Sei sicuro?",
            undone: "Non potrai annullare questa azione",
            yes: "Sì",
            cancel: "Annulla",
            delete: "Elimina",
            repaint: "Ridisegna",
            blurred:
                "Se hai sempre un'immagine sfocata, prova a rimuovere le parole chiave o il contenuto non appropriato",
            split: "Dividere la cella?",
            open: "Apri",
            watch: "Guarda",
            deleteStory: "Eliminare questa storia?",
            deleteSlide: "Eliminare questa immagine?",
            publish: "Pubblicare questa storia?",

            title: "Titolo",
            details: "Dettagli",
            category: "Categoria",
            image: "Image",
            upload: "Caricare su Youtube?",
            profile: "Il mio profilo",

            description: "Descrizione dell'evento",
            descriptionHint:
                "È meglio iniziare con 'Crea / Racconta / Scrivi una storia su ...' o semplicemente iniziare con la storia e impostare i parametri qui sotto. E per favore evita contenuti inappropriati😏",
            lang: "Lingua",
            voice: "Voce",
            setting: "Impostazioni visive",
            music: "Musica",
            include: "Includi la descrizione nella storia",
            model: "Modello",
            imageModel: "Modello di immagine",
            temperature: "Temperatura",
            hint: "Il video si trova nel mio account dopo la conclusione",
            video: "Video",
            generate: "Crea",
            pictures: "Immagini",
            continue: "Continua a creare",

            search: "Ricerca",
            searchStories: "Cerca storie",
            home: "Home",
            new: "Crea",
            dashboard: "Dashboard",

            account: "Il mio account",
            signin: "Accedi",
            logout: "Esci",
            signup: "Registrati",
            forgot: "Password dimenticata?",
            reset: "Reimposta password",
            set: "Imposta password",
            newpass: "Nuova password",
            retype: "Riscrivi password",
            dont: "Registrati",
            remember: "Ricordami",
            password: "Password",
            email: "Email",
            first: "Nome",
            last: "Cognome",
            already: "Hai già un account? Accedi",
            marketing: "Voglio ricevere email con novità, offerte promozionali e aggiornamenti.",

            plan: "Piano",
            balance: "Minuti video rimanenti",
            referral: "Codice di riferimento",
            free_minutes: "2 minuti gratuiti al giorno",
            share: "Condividi il tuo codice di riferimento e ricevi 20 minuti gratis",
            manga: "Manga TV AI Comics Generator con il 20% di sconto! ❤️",
            over: "I tuoi minuti sono finiti! Per favore acquista più minuti o aspetta il giorno successivo ❤️",
            payment: "È richiesto il pagamento",
            featured: "In primo piano",
            eligible: "Il piano gratuito è disponibile a seconda del carico del server",
            hint2: "Una volta che il video è pronto, ti offriamo di ridisegnare le immagini sbagliate, correggere i testi se necessario, rimuovere le diapositive sbagliate e premere il pulsante Riprova video 🤗",
            uploadImage: "Caricare",
            custom: "Stili personalizzati",
            customText:
                "Qui puoi creare stili personalizzati per le tue storie. Fai clic sul pulsante + per aggiungerne uno nuovo",
            randomize: "Casuale",
            effect: "Effetto video",
            myVoices: "Le mie voci",
        },
    },
    es: {
        translation: {
            welcome: `Descubre historias creadas por nuestra comunidad. Solo proporciona una sugerencia y la inteligencia artificial generará una historia completa en video para ti.`,
            inspiration: "¡Inspírate!",
            buy: "Comprar paquete",
            try: "Probar",
            ready: "🦄 Video listo!",
            paid: "Esta función solo está disponible en un paquete de pago",

            pricing: "Precios",
            onetime: "Una vez",
            select: "No se requiere suscripción, solo realiza un pago único. ¡Compra tantos minutos como necesites.",
            title1: "Gratis",
            body11: `${FREE_MINUTES} minutos de video incluidos`,
            body12: "10 imágenes en la historia",
            body16: "Estilos básicos",
            body26: "Estilos personalizados",
            body13: "Voz Standard",
            body14: "Contenido público",
            body15: "Probar gratis",
            subheader: "Más popular",
            body211: "1.5 hora de video incluida",
            body21: "5 horas de video incluidas",
            body22: "20 imágenes en la historia",
            body23: "Voz WaveNet",
            body24: "Derechos de autor, PDF",
            body25: "Comprar",
            body31: "25 horas de video incluidas",
            body32: "∞ imágenes",
            body33: "Voz OpenAI/ElevenLabs",
            body34: "Carga automática en YouTube",

            sure: "¿Estás seguro?",
            undone: "No podrás deshacer esta acción",
            yes: "Sí",
            cancel: "Cancelar",
            delete: "Eliminar",
            repaint: "Redibujar",
            blurred:
                "Si siempre tienes una imagen borrosa, prueba a eliminar las palabras clave o el contenido no apropiado",
            split: "¿Dividir la celda?",
            open: "Abrir",
            watch: "Ver",
            deleteStory: "¿Eliminar esta historia?",
            deleteSlide: "¿Eliminar esta imagen?",
            publish: "¿Publicar esta historia?",

            title: "Título",
            details: "Detalles",
            category: "Categoría",
            image: "Imagen",
            upload: "¿Subir a Youtube?",
            profile: "Mi perfil",

            description: "Descripción del evento",
            descriptionHint:
                "Es mejor comenzar con 'Crea / Cuenta / Escribe una historia sobre ...' o simplemente comenzar con la historia y configurar los parámetros a continuación. Y evite el contenido inapropiado😏",
            lang: "Idioma",
            voice: "Voz",
            setting: "Ajustes visuales",
            music: "Música",
            include: "Incluir la descripción en la historia",
            model: "Modelo",
            imageModel: "Modelo de imagen",
            temperature: "Temperatura",
            hint: "El video se encuentra en mi cuenta después de completar",
            video: "Video",
            generate: "Crear",
            pictures: "Imágenes",
            continue: "Continuar creando",

            search: "Buscar",
            searchStories: "Buscar historias",
            home: "Inicio",
            new: "Crear",
            dashboard: "Dashboard",

            account: "Mi cuenta",
            signin: "Iniciar sesión",
            logout: "Cerrar sesión",
            signup: "Registrarse",
            forgot: "¿Olvidaste tu contraseña?",
            reset: "Restablecer contraseña",
            set: "Establecer contraseña",
            newpass: "Nueva contraseña",
            retype: "Vuelva a escribir la contraseña",
            dont: "Regístrate",
            remember: "Recuérdame",
            password: "Contraseña",
            email: "Correo electrónico",
            first: "Nombre",
            last: "Apellido",
            already: "¿Ya tienes una cuenta? Inicia sesión",
            marketing: "Quiero recibir correos electrónicos con noticias, ofertas promocionales y actualizaciones.",

            plan: "Plan",
            balance: "Minutos de video restantes",
            referral: "Código de referencia",
            free_minutes: "2 minutos gratis al día",
            share: "Comparte tu código de referencia y obtén 20 minutos gratis",
            manga: "¡Manga TV AI Comics Generator con un 20% de descuento! ❤️",
            over: "¡Tus minutos se han acabado! Por favor, compra más minutos o espera al día siguiente ❤️",
            payment: "Pago requerido",
            featured: "Destacado",
            eligible: "El plan gratuito está disponible según la carga del servidor",
            hint2: "Una vez que el video esté listo, le ofrecemos volver a dibujar las imágenes incorrectas, corregir los textos si es necesario, eliminar las diapositivas incorrectas y presionar el botón Volver a intentar video 🤗",
            uploadImage: "Subir",
            custom: "Estilos personalizados",
            customText:
                "Aquí puede crear estilos personalizados para sus historias. Haga clic en el botón + para agregar uno nuevo",
            randomize: "Aleatorio",
            effect: "Efecto de vídeo",
            myVoices: "Mis voces",
        },
    },
    fr: {
        translation: {
            welcome: `Découvrez des histoires créées par notre communauté. Fournissez simplement un indicateur, et l'IA générera une histoire vidéo complète pour vous.`,
            inspiration: "S'inspirer!",
            buy: "Acheter un forfait",
            try: "Essayer",
            ready: "🦄 Vidéo prête!",
            paid: "Cette fonctionnalité n'est disponible que dans un forfait payant",

            pricing: "Tarifs",
            onetime: "Une fois",
            select: "Aucun abonnement requis, faites simplement un paiement unique. Achetez autant de minutes que vous avez besoin.",
            title1: "Gratuit",
            body11: `${FREE_MINUTES} minutes de vidéo incluses`,
            body12: "10 images dans l'histoire",
            body16: "Styles de base",
            body26: "Styles personnalisés",
            body13: "Voix Standard",
            body14: "Contenu public",
            body15: "Essayer gratuitement",
            subheader: "Le plus populaire",
            body211: "1.5 heure de vidéo incluse",
            body21: "5 heures de vidéo incluses",
            body22: "20 images dans l'histoire",
            body23: "Voix WaveNet",
            body24: "Droits d'auteur, PDF",
            body25: "Acheter",
            body31: "25 heures de vidéo incluses",
            body32: "∞ images",
            body33: "Voix Neuronale",
            body34: "Téléchargement automatique sur YouTube",

            sure: "Êtes-vous sûr?",
            undone: "Vous ne pourrez pas annuler cette action",
            yes: "Oui",
            cancel: "Annuler",
            delete: "Supprimer",
            repaint: "Redessiner",
            blurred:
                "Si vous avez toujours une image floue, essayez de supprimer les mots-clés ou le contenu non approprié",
            split: "Diviser la cellule?",
            open: "Ouvrir",
            watch: "Regarder",
            deleteStory: "Supprimer cette histoire?",
            deleteSlide: "Supprimer cette image?",
            publish: "Publier cette histoire?",

            title: "Titre",
            details: "Détails",
            category: "Catégorie",
            image: "Image",
            upload: "Télécharger sur Youtube?",
            profile: "Mon profil",

            description: "Description de l'événement",
            descriptionHint:
                "Il est préférable de commencer par 'Créer / Compte / Écrire une histoire sur ...' ou simplement commencer par l'histoire et définir les paramètres ci-dessous. Et évitez le contenu inapproprié😏",
            lang: "Langue",
            voice: "Voix",
            setting: "Paramètres visuels",
            music: "Musique",
            include: "Inclure la description dans l'histoire",
            model: "Modèle",
            imageModel: "Modèle d'image",
            temperature: "Température",
            hint: "La vidéo se trouve dans mon compte après avoir terminé",
            video: "Vidéo",
            generate: "Créer",
            pictures: "Images",
            continue: "Continuer à créer",

            search: "Chercher",
            searchStories: "Rechercher des histoires",
            home: "Accueil",
            new: "Créer",
            dashboard: "Tableau de bord",

            account: "Mon compte",
            signin: "Se connecter",
            logout: "Se déconnecter",
            signup: "S'inscrire",
            forgot: "Mot de passe oublié?",
            reset: "Réinitialiser le mot de passe",
            set: "Définir le mot de passe",
            newpass: "Nouveau mot de passe",
            retype: "Retaper le mot de passe",
            dont: "S'inscrire",
            remember: "Souviens-toi de moi",
            password: "Mot de passe",
            email: "Email",
            first: "Prénom",
            last: "Nom de famille",
            already: "Vous avez déjà un compte? Connectez-vous",
            marketing:
                "Je veux recevoir des e-mails avec des nouvelles, des offres promotionnelles et des mises à jour.",

            plan: "Plan",
            balance: "Minutes vidéo restantes",
            referral: "Code de référence",
            free_minutes: "2 minutes gratuites par jour",
            share: "Partagez votre code de référence et obtenez 20 minutes gratuites",
            manga: "Manga TV AI Comics Generator avec 20% de réduction! ❤️",
            over: "Vos minutes sont écoulées! Veuillez acheter plus de minutes ou attendre le lendemain ❤️",
            payment: "Paiement requis",
            featured: "En vedette",
            eligible: "Le plan gratuit est disponible en fonction de la charge du serveur",
            hint2: "Une fois la vidéo prête, nous vous proposons de redessiner les images incorrectes, de corriger les textes si nécessaire, de supprimer les diapositives incorrectes et d'appuyer sur le bouton Réessayer la vidéo 🤗",
            uploadImage: "Télécharger",
            custom: "Styles personnalisés",
            customText:
                "Ici, vous pouvez créer des styles personnalisés pour vos histoires. Cliquez sur le bouton + pour en ajouter un nouveau",
            randomize: "Aléatoire",
            effect: "Effet vidéo",
            myVoices: "Mes voix",
        },
    },
    pt: {
        translation: {
            welcome:
                "Descubra histórias criadas pela nossa comunidade. Basta fornecer um prompt, e a IA gerará uma história completa em vídeo para você.",
            inspiration: "Obtenha inspiração!",
            buy: "Comprar pacote",
            try: "Experimente gratuitamente",
            ready: "🦄 Vídeo está pronto!",
            paid: "Recurso do plano pago",
            summary: "Resumo do pedido",
            minutes: "100 minutos de vídeo incluídos",
            minutes2: "300 minutos de vídeo incluídos",
            minutes3: "1500 minutos de vídeo incluídos",
            review: "Reveja o seu pedido",
            checkout: "Finalizar compra",
            pricing: "Preços",
            onetime: "Pagamento único",
            select: "Nenhuma assinatura é necessária - basta fazer um pagamento único. Compre quantos minutos você precisar.",
            title1: "Grátis",
            body11: `Inclui ${FREE_MINUTES} minutos de vídeo`,
            body12: "10 slides na história",
            body13: "Vozes padrão",
            body14: "Conteúdo público",
            body15: "Cadastre-se gratuitamente",
            body16: "Estilos básicos",
            body26: "Estilos personalizados",
            title2: "Prata",
            subheader: "Mais popular",
            title21: "Bronze",
            body211: "1,5 hora de vídeo incluída",
            body21: "5 horas de vídeo incluídas",
            body22: "20 slides na história",
            body23: "Vozes WaveNet",
            body24: "Vídeos isentos de royalties, PDF",
            body25: "Comprar",
            title3: "Ouro",
            body31: "25 horas de vídeo incluídas",
            body32: "Slides infinitos",
            body33: "Vozes OpenAI/ElevenLabs",
            body34: "Upload automático para o YouTube",
            sure: "Tem certeza?",
            undone: "Você não poderá desfazer esta ação",
            yes: "Sim",
            cancel: "Cancelar",
            delete: "Excluir",
            repaint: "Repintar",
            blurred: "Se a imagem estiver sempre desfocada, tente remover palavras de parada ou conteúdo explícito",
            split: "Dividir a célula?",
            open: "Abrir",
            watch: "Assistir",
            deleteStory: "Excluir esta história?",
            storyDeleted: "A história foi excluída.",
            mark: "Marcar esta história como apropriada?",
            markUploaded: "Marcar esta história como já carregada?",
            marked: "História marcada como boa",
            deleteSlide: "Excluir este slide?",
            publish: "Publicar esta história privada?",
            published: "A história foi publicada",
            done: "Concluído",
            error: "Erro",
            title: "Título",
            details: "Detalhes",
            category: "Categoria",
            image: "Imagem",
            upload: "Enviar para o YouTube?",
            upload_tiktok: "Enviar para o TikTok?",
            profile: "Meu Perfil",
            description: "Descrição da história",
            descriptionHint:
                "Melhor começar com 'Crie/Me conte/Escreva uma história sobre...' Ou apenas inicie uma história e configure a opção abaixo. E evite conteúdo explícito 😏",
            lang: "Idioma",
            voice: "Voz",
            setting: "Configuração visual",
            music: "Música",
            autoFlow: "Automático",
            vertical: "Vertical",
            include: "Incluir descrição na história",
            model: "Modelo de texto",
            imageModel: "Modelo de imagem",
            temperature: "Temperatura",
            hint: "Você pode encontrar o vídeo em Minha Conta depois que ele estiver pronto",
            video: "Vídeo",
            generate: "Gerar",
            pictures: "Imagens",
            continue: "Continuar história",
            search: "Pesquisar",
            searchStories: "Pesquisar histórias",
            home: "Página inicial",
            new: "Criar",
            dashboard: "Painel",
            private: "Histórias privadas",
            account: "Minha Conta",
            signin: "Entrar",
            signup: "Cadastre-se",
            logout: "Sair",
            forgot: "Esqueceu a senha?",
            reset: "Redefinir senha",
            set: "Definir senha",
            newpass: "Nova Senha",
            retype: "Redigite a senha",
            dont: "Não tem uma conta? Cadastre-se",
            remember: "Lembrar-me",
            password: "Senha",
            email: "Endereço de e-mail",
            first: "Primeiro Nome",
            last: "Sobrenome",
            already: "Já tem uma conta? Entre",
            marketing: "Desejo receber inspiração, promoções de marketing e atualizações por e-mail",
            plan: "Plano",
            balance: "Minutos de vídeo restantes",
            referral: "Código de Referência",
            free_minutes: "2 minutos grátis todos os dias",
            share: "Compartilhe seu código de referência para ganhar 20 minutos grátis",
            manga: "Gerador de Quadrinhos AI Manga TV com 20% de desconto! ❤️",
            over: "Seus minutos acabaram! Compre mais minutos ou aguarde até o próximo dia ❤️",
            payment: "Pagamento necessário",
            featured: "Seleção do Editor",
            eligible: "A disponibilidade do plano gratuito depende da carga do servidor",
            hint2: "Após a história estar pronta, sugerimos que você repinte imagens ruins, remova slides incorretos e pressione o botão de Vídeo novamente 🤗",
            uploadImage: "Enviar",
            custom: "Visuais personalizados",
            customText:
                "Aqui você pode criar configurações visuais personalizadas para suas histórias. Clique no botão + para adicionar uma nova",
            randomize: "Aleatorizar",
            effect: "Efeito de vídeo",
            myVoices: "Minhas vozes",
        },
    },
    zh: {
        translation: {
            welcome: "发现我们社区创作的故事。只需提供提示，人工智能将为您生成完整的视频故事。",
            inspiration: "获取灵感！",
            buy: "购买套餐",
            try: "免费试用",
            ready: "🦄 视频已准备就绪！",
            paid: "这是付费计划的功能",
            summary: "订单摘要",
            minutes: "包括100分钟视频",
            minutes2: "包括300分钟视频",
            minutes3: "包括1500分钟视频",
            review: "查看您的订单",
            checkout: "结账",
            pricing: "价格",
            onetime: "一次性付款",
            select: "无需订阅 - 只需进行一次性付款。购买所需的分钟数",
            title1: "免费",
            body11: `包括 ${FREE_MINUTES} 分钟的视频`,
            body12: "故事中的10张幻灯片",
            body13: "标准语音",
            body14: "公共内容",
            body15: "免费注册",
            body16: "基本样式",
            body26: "自定义样式",
            title2: "白银",
            subheader: "最受欢迎",
            title21: "青铜",
            body211: "包括1.5小时的视频",
            body21: "包括5小时的视频",
            body22: "故事中的20张幻灯片",
            body23: "WaveNet语音",
            body24: "无版税视频，PDF",
            body25: "购买",
            title3: "黄金",
            body31: "包括25小时的视频",
            body32: "∞ 幻灯片",
            body33: "OpenAI语音",
            body34: "自动YouTube上传",
            sure: "您确定吗？",
            undone: "您将无法撤销此操作",
            yes: "是的",
            cancel: "取消",
            delete: "删除",
            repaint: "重新绘制",
            blurred: "如果您始终有模糊的图像，请尝试删除停用词或不当内容",
            split: "拆分单元格？",
            open: "打开",
            watch: "观看",
            deleteStory: "删除此故事？",
            storyDeleted: "故事已被删除。",
            mark: "将此故事标记为适当吗？",
            markUploaded: "将此故事标记为已上传？",
            marked: "故事标记为良好",
            deleteSlide: "删除此幻灯片？",
            publish: "发布此私人故事？",
            published: "故事已发布",
            done: "完成",
            error: "错误",
            title: "标题",
            details: "详情",
            category: "类别",
            image: "图像",
            upload: "上传至YouTube？",
            upload_tiktok: "上传至TikTok？",
            profile: "我的个人资料",
            description: "故事描述",
            descriptionHint: "最好以“创建/告诉我/写一个关于...的故事”开始。请避免不适内容😏",
            lang: "语言",
            voice: "语音",
            setting: "视觉设置",
            music: "音乐",
            autoFlow: "自动",
            vertical: "竖屏",
            include: "将描述包括在故事中",
            model: "文本模型",
            imageModel: "图像模型",
            temperature: "温度",
            hint: "视频准备好后，您可以在“我的帐户”中找到它",
            video: "视频",
            generate: "生成",
            pictures: "图片",
            continue: "继续故事",
            search: "搜索",
            searchStories: "搜索故事",
            home: "主页",
            new: "创建",
            dashboard: "仪表板",
            private: "私人故事",
            account: "我的帐户",
            signin: "登录",
            signup: "注册",
            logout: "注销",
            forgot: "忘记密码？",
            reset: "重设密码",
            set: "设置密码",
            newpass: "新密码",
            retype: "重新输入密码",
            dont: "没有帐户？注册",
            remember: "记住我",
            password: "密码",
            email: "电子邮件地址",
            first: "名字",
            last: "姓氏",
            already: "已经有帐户了？登录",
            marketing: "我希望通过电子邮件接收灵感、营销促销和更新",
            plan: "计划",
            balance: "剩余视频分钟",
            referral: "推荐码",
            free_minutes: "每天2分钟免费",
            share: "分享您的推荐码以获得额外20分钟",
            manga: "Manga TV AI漫画生成器，享受20%折扣！ ❤️",
            over: "您的分钟用完了！请购买更多分钟或等待第二天 ❤️",
            payment: "需要付款",
            featured: "编辑选择",
            eligible: "免费计划的可用性取决于服务器负载",
            hint2: "故事完成后，建议您重新绘制不好的图片，删除错误的幻灯片，然后再次按“视频”按钮 🤗",
            uploadImage: "上传",
            custom: "自定义视觉效果",
            customText: "在这里，您可以为您的故事创建自定义视觉设置。单击+按钮添加新的设置",
            randomize: "随机化",
            effect: "视频效果",
            myVoices: "我的声音",
        },
    },
    ar: {
        translation: {
            welcome:
                "اكتشف القصص التي أنشأها مجتمعنا. فقط قدم محفزًا، وسيقوم الذكاء الاصطناعي بإنشاء قصة فيديو كاملة لك.",
            inspiration: "احصل على إلهام!",
            buy: "شراء الحزمة",
            try: "جرب مجاناً",
            ready: "🦄 الفيديو جاهز!",
            paid: "هذا هو ميزة الخطة المدفوعة",
            summary: "ملخص الطلب",
            minutes: "100 دقيقة من الفيديو مدرجة",
            minutes2: "300 دقيقة من الفيديو مدرجة",
            minutes3: "1500 دقيقة من الفيديو مدرجة",
            review: "مراجعة طلبك",
            checkout: "الدفع",
            pricing: "الأسعار",
            onetime: "الدفع لمرة واحدة",
            select: "Arabic (ar): اختر الخيار المثالي بالنسبة لك. لا حاجة للاشتراك - قم بالدفع مرة واحدة فقط. اشترِ كل الدقائق التي تحتاجها",
            title1: "مجاني",
            body11: `تتضمن ${FREE_MINUTES} دقيقة من الفيديو`,
            body12: "10 شرائح في القصة",
            body13: "أصوات قياسية",
            body14: "محتوى عام",
            body15: "التسجيل مجاني",
            body16: "أنماط أساسية",
            body26: "أنماط مخصصة",
            title2: "فضي",
            subheader: "الأكثر شيوعاً",
            title21: "برونز",
            body211: "تتضمن 1.5 ساعة من الفيديو",
            body21: "تتضمن 5 ساعات من الفيديو",
            body22: "20 شريحة في القصة",
            body23: "أصوات WaveNet",
            body24: "فيديوهات خالية من العائدات، PDF",
            body25: "شراء",
            title3: "ذهبي",
            body31: "تتضمن 25 ساعة من الفيديو",
            body32: "شرائح غير محدودة",
            body33: "أصوات OpenAI/ElevenLabs",
            body34: "تحميل آلي على اليوتيوب",
            sure: "هل أنت متأكد؟",
            undone: "لن تكون قادرًا على التراجع عن هذا الإجراء",
            yes: "نعم",
            cancel: "إلغاء",
            delete: "حذف",
            repaint: "إعادة رسم",
            blurred: "إذا كان لديك دائمًا صورة غامضة، جرب إزالة الكلمات التوقفية أو المحتوى الصريح",
            split: "هل تريد تقسيم الخلية؟",
            open: "فتح",
            watch: "مشاهدة",
            deleteStory: "هل تريد حذف هذه القصة؟",
            storyDeleted: "تم حذف القصة.",
            mark: "هل تريد وسم هذه القصة كملائمة؟",
            markUploaded: "هل تريد وسم هذه القصة بالفعل تم تحميلها؟",
            marked: "تم وسم القصة كجيدة",
            deleteSlide: "هل تريد حذف هذا الشريحة؟",
            publish: "هل تريد نشر هذه القصة الخاصة؟",
            published: "تم نشر القصة",
            done: "تم",
            error: "خطأ",
            title: "العنوان",
            details: "تفاصيل",
            category: "الفئة",
            image: "صورة",
            upload: "التحميل إلى اليوتيوب؟",
            upload_tiktok: "التحميل إلى تيك توك؟",
            profile: "ملفي الشخصي",
            description: "وصف القصة",
            descriptionHint:
                "من الأفضل أن تبدأ بعبارة 'أنشئ/أخبرني/اكتب قصة عن...' أو ابدأ بالقصة واضبط الخيار أدناه. يرجى تجنب المحتوى الصريح 😏",
            lang: "اللغة",
            voice: "الصوت",
            setting: "إعدادات الرؤية",
            music: "الموسيقى",
            autoFlow: "تدفق تلقائي",
            vertical: "عمودي",
            include: "تضمين الوصف في القصة",
            model: "نموذج النص",
            imageModel: "نموذج الصورة",
            temperature: "درجة الحرارة",
            hint: "يمكنك العثور على الفيديو في حسابي بعد أن يكون جاهزًا",
            video: "الفيديو",
            generate: "إنشاء",
            pictures: "الصور",
            continue: "متابعة القصة",
            search: "البحث",
            searchStories: "البحث عن القصص",
            home: "الصفحة الرئيسية",
            new: "إنشاء",
            dashboard: "لوحة التحكم",
            private: "القصص الخاصة",
            account: "حسابي",
            signin: "تسجيل الدخول",
            signup: "التسجيل",
            logout: "تسجيل الخروج",
            forgot: "نسيت كلمة المرور؟",
            reset: "إعادة تعيين كلمة المرور",
            set: "تعيين كلمة المرور",
            newpass: "كلمة المرور الجديدة",
            retype: "أعد كتابة كلمة المرور",
            dont: "ليس لديك حساب؟ التسجيل",
            remember: "تذكرني",
            password: "كلمة المرور",
            email: "عنوان البريد الإلكتروني",
            first: "الاسم الأول",
            last: "الاسم الأخير",
            already: "هل لديك حساب بالفعل؟ تسجيل الدخول",
            marketing: "أرغب في تلقي إلهام، عروض تسويقية وتحديثات عبر البريد الإلكتروني",
            plan: "الخطة",
            balance: "الدقائق المتبقية من الفيديو",
            referral: "رمز الإحالة",
            free_minutes: "2 دقيقة مجانية كل يوم",
            share: "شارك رمز الإحالة الخاص بك للحصول على 20 دقيقة إضافية مجانية",
            manga: "مولد مانجا تلفزيون الذكاء الاصطناعي مع خصم 20%! ❤️",
            over: "انتهت دقائقك! يرجى شراء المزيد من الدقائق أو الانتظار لليوم التالي ❤️",
            payment: "الدفع مطلوب",
            featured: "اختيار المحرر",
            eligible: "تتوقف توافر الخطة المجانية على حمل الخادم",
            hint2: "بعد اكتمال القصة، نقترح عليك إعادة رسم الصور السيئة، وإزالة الشرائح الخاطئة، ثم الضغط مرة أخرى على زر الفيديو 🤗",
            uploadImage: "تحميل",
            custom: "إعدادات بصرية مخصصة",
            customText: "هنا يمكنك إنشاء إعدادات بصرية مخصصة لقصصك. انقر على زر + لإضافة جديدة",
            randomize: "تعشيش",
            effect: "تأثير الفيديو",
            myVoices: "أصواتي",
        },
    },
};

i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
