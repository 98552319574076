import Slide from "./Slide";
import { API } from "../App";

export const Slides = ({
    script,
    vertical,
    splitCell,
    setSlideCaption,
    repaintImage,
    convertToVideo,
    setSlideImage,
    deleteSlide,
    listenAudio,
}) => {
    return script.map((slide, index) => (
        <Slide
            key={index}
            time={new Date(
                script
                    .slice(0, index + 1)
                    .map((slide) => slide.audio?.duration ?? 0)
                    .reduce((a, b) => a + b, 0) * 1000
            )
                .toTimeString()
                .slice(3, 8)}
            splitCell={() => splitCell(index)}
            caption={slide.caption}
            vertical={vertical}
            image={slide.image && API + slide.image}
            setName={(caption) => setSlideCaption(index, caption)}
            repaintImage={() => repaintImage(index)}
            convertToVideo={() => convertToVideo(index)}
            uploadImage={(image) => setSlideImage(index, image)}
            deleteSlide={() => deleteSlide(index)}
            listenAudio={() => listenAudio(index)}
        />
    ));
};
