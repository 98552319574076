import { Container, CssBaseline, Typography } from "@mui/material";

const Terms = (props) => {
    return (
        <>
            <CssBaseline />
            <Container maxWidth="md" component="main" sx={{ px: 6, pt: 8, pb: 6 }} spacing={2}>
                <Typography variant="h3">Terms of service</Typography>
                <br />
                <Typography variant="body2">MangaTV.shop is owned by MaxSoft, Ltd. All rights reserved.</Typography>
                <br />

                <Typography variant="body2">
                    1. Terms. By accessing the website at https://www.MangaTV.shop, you are agreeing to be bound by
                    these terms of service, all applicable laws and regulations, and agree that you are responsible for
                    compliance with any applicable local laws. If you do not agree with any of these terms, you are
                    prohibited from using or accessing this site. The materials contained in this website are protected
                    by applicable copyright and trademark law.
                </Typography>
                <br />

                <Typography variant="body2">
                    2. Use License. Permission is granted to temporarily download one copy of the materials (information
                    or software) on MangaTV.shop's website for personal, non-commercial transitory viewing only. This is
                    the grant of a license, not a transfer of title, and under this license you may not: modify or copy
                    the materials; attempt to decompile or reverse engineer any software contained on MangaTV.shop's
                    website; remove any copyright or other proprietary notations from the materials; or transfer the
                    materials to another person or "mirror" the materials on any other server. This license shall
                    automatically terminate if you violate any of these restrictions and may be terminated by
                    MangaTV.shop at any time. Upon terminating your viewing of these materials or upon the termination
                    of this license, you must destroy any downloaded materials in your possession whether in electronic
                    or printed format.
                </Typography>
                <br />

                <Typography variant="body2">
                    3. Disclaimer. The materials on MangaTV.shop's website are provided on an 'as is' basis.
                    MangaTV.shop makes no warranties, expressed or implied, and hereby disclaims and negates all other
                    warranties including, without limitation, implied warranties or conditions of merchantability,
                    fitness for a particular purpose, or non-infringement of intellectual property or other violation of
                    rights. Further, MangaTV.shop does not warrant or make any representations concerning the accuracy,
                    likely results, or reliability of the use of the materials on its website or otherwise relating to
                    such materials or on any sites linked to this site.
                </Typography>
                <br />

                <Typography variant="body2">
                    4. Limitations. In no event shall MangaTV.shop or its suppliers be liable for any damages
                    (including, without limitation, damages for loss of data or profit, or due to business interruption)
                    arising out of the use or inability to use the materials on MangaTV.shop's website, even if
                    MangaTV.shop or a MangaTV.shop authorized representative has been notified orally or in writing of
                    the possibility of such damage. Because some jurisdictions do not allow limitations on implied
                    warranties, or limitations of liability for consequential or incidental damages, these limitations
                    may not apply to you.
                </Typography>
                <br />

                <Typography variant="body2">
                    5. Accuracy of materials. The materials appearing on MangaTV.shop's website could include technical,
                    typographical, or photographic errors. MangaTV.shop does not warrant that any of the materials on
                    its website are accurate, complete or current. MangaTV.shop may make changes to the materials
                    contained on its website at any time without notice. However MangaTV.shop does not make any
                    commitment to update the materials.
                </Typography>
                <br />

                <Typography variant="body2">
                    6. Links. MangaTV.shop has not reviewed all of the sites linked to its website and is not
                    responsible for the contents of any such linked site. The inclusion of any link does not imply
                    endorsement by MangaTV.shop of the site. Use of any such linked website is at the user's own risk.
                </Typography>
                <br />

                <Typography variant="body2">
                    7. Modifications. MangaTV.shop may revise these terms of service for its website at any time without
                    notice. By using this website you are agreeing to be bound by the then current version of these
                    terms of service.
                </Typography>
                <br />

                <Typography variant="body2">
                    8. Governing Law. These terms and conditions are governed by and construed in accordance with the
                    laws of Delaware, USA and you irrevocably submit to the exclusive jurisdiction of the courts in that
                    State or location.
                </Typography>
                <br />

                <Typography variant="body2">
                    9. MangaTV.shop offers a free plan that allows users to create and publish videos on the Manga TV
                    YouTube channel automatically. By opting for the free plan, users grant MangaTV.shop the right to
                    publish their created content on the official Manga TV YouTube channel. Users understand that this
                    content may be visible to the public on the Manga TV YouTube channel and related platforms.
                    MangaTV.shop reserves the right to determine the criteria for featuring or removing content on the
                    YouTube channel. Users on the free plan acknowledge that their videos may be subject to
                    MangaTV.shop's promotional efforts. MangaTV.shop retains the right to modify or discontinue the free
                    plan, including the auto-publishing feature, at its discretion. Users are encouraged to review and
                    comply with YouTube's policies and guidelines in conjunction with MangaTV.shop's terms of service.
                </Typography>

                <br />

                <Typography variant="body2">
                    10. Users who subscribe to the paid plan on MangaTV.shop enjoy enhanced benefits and
                    protection. With the paid plan, users are assured that their videos remain private and are not
                    automatically published on the Manga TV YouTube channel. MangaTV.shop respects the privacy of users
                    on the paid plan, ensuring that their content is exclusively accessible to them and their designated
                    audience. Additionally, users on the paid plan retain full ownership of the intellectual property
                    rights to their videos, and MangaTV.shop guarantees that these videos are royalty-free. This means
                    that users have the freedom to control the usage and distribution of their content without concerns
                    about royalties or licensing fees. MangaTV.shop may provide additional features and support to users
                    on the paid plan, as outlined in the plan's terms and conditions.
                </Typography>
            
            </Container>
        </>
    );
};

export default Terms;
