import { API, UserContext } from "../App";
import { Typography, CssBaseline, Container, Paper, Box, List, ListItem, ListItemText } from "@mui/material/";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Copyright } from "./../UI/Copyright";
import { Button } from "@mui/material";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

export default function Checkout() {
    const { t } = useTranslation();
    const { id } = useParams();
    const user = useContext(UserContext);

    const products = [
        {
            name: t("title21"),
            desc: t("minutes") + ", GPT-3.5, SD XL, SVD 1.1",
            price: "9.99",
        },
        {
            name: t("title2"),
            desc: t("minutes2") + ", GPT-4, Stable Diffusion 3, SVD 1.1, DALL-E, Imagen, Gemini 1.5, Mistral, Claude 3 Opus, ElevenLabs",
            price: user?.referrer ? "19.99" : "24.99",
        },
        {
            name: t("title3"),
            desc: t("minutes3") + ", GPT-4, Stable Diffusion 3, SVD 1.1, DALL-E, Imagen, Gemini 1.5, Mistral, Claude 3 Opus, ElevenLabs",
            price: user?.referrer ? "79.99" : "99.99",
        },
    ];

    const Review = () => (
        <>
            <Typography variant="h6" gutterBottom>
                One-time payment
            </Typography>
            <List disablePadding>
                <ListItem key={products[id].name} sx={{ py: 1, px: 0 }}>
                    <ListItemText primary={products[id].name} secondary={products[id].desc} />
                    <Typography variant="body2" sx={{ m: 1 }}>
                        ${products[id].price}
                    </Typography>
                </ListItem>
            </List>
        </>
    );

    const handleSubmit = (event) => {
        if (!user?.email) {
            event.preventDefault();
        }
    };

    return (
        <>
            <CssBaseline />
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    {id === "success" && (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Thank you for your order.
                            </Typography>
                            <Typography variant="subtitle1">
                                Your order number is #{Math.floor(100000 + Math.random() * 900000)}. We have emailed
                                your order confirmation, your plan was successfully changed.
                            </Typography>
                        </>
                    )}
                    {id === "cancel" && (
                        <>
                            <Typography variant="h5" gutterBottom>
                                Cancelled order.
                            </Typography>
                            <Typography variant="subtitle1">
                                Order canceled - continue your trial and checkout when you're ready.
                            </Typography>
                        </>
                    )}

                    {(id === "0" || id === "1" || id === "2") && (
                        <>
                            <Typography component="h1" variant="h4" align="center">
                                {t("checkout")}
                            </Typography>
                            <Stepper activeStep={0} sx={{ pt: 3, pb: 5 }}>
                                <Step>
                                    <StepLabel>{t("review")}</StepLabel>
                                </Step>
                            </Stepper>
                            <Review />
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <form
                                    action={`${API}/checkout/${id}/${user?.email}`}
                                    onSubmit={handleSubmit}
                                    method="POST"
                                >
                                    <Button
                                        onClick={ReactGA.event({
                                            category: "buy",
                                            action: "begin_checkout",
                                            value: 0,
                                        })}
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        Pay by Stripe™
                                    </Button>
                                </form>
                            </Box>
                        </>
                    )}
                </Paper>
                <Copyright />
            </Container>
        </>
    );
}
