import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../UI/Copyright";
import Stories from "./Stories";
import { useContext } from "react";
import { UserContext } from "../App";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Fab, Zoom } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function Gallery() {
    const { t } = useTranslation();
    const user = useContext(UserContext);

    return (
        <>
            <Helmet>
                <title>Manga TV</title>
            </Helmet>
            <Zoom
                in={true}
                timeout={{
                    enter: 300,
                    exit: 300,
                }}
                style={{
                    transitionDelay: "300ms",
                }}
                unmountOnExit
            >
                <Fab
                    sx={{
                        position: "fixed",
                        zIndex: "1000",
                        bottom: 20,
                        right: 20,
                    }}
                    color="secondary"
                    component={Link}
                    to="/story"
                    aria-label="add"
                >
                    <AddIcon />
                </Fab>
            </Zoom>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                        {t("inspiration")}
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        {t("welcome")}
                    </Typography>
                    <Stack sx={{ pt: 4 }} direction="row" spacing={2} justifyContent="center">
                        <Button component={Link} to="/buy" variant="contained">
                            {t("buy")}
                        </Button>
                        <Button component={Link} to="/story" variant="outlined">
                            {t("try")}
                        </Button>
                    </Stack>
                </Container>
            </Box>
            <Stories admin={user?.admin} />
            <Copyright />
        </>
    );
}
