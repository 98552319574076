import { Copyright } from "../UI/Copyright";
import { UserContext } from "../App";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CssBaseline,
    Grid,
    Typography,
    Container,
    GlobalStyles,
    Link,
    Tooltip,
} from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

export const FREE_MINUTES = 5;

const footers = [
    {
        title: "Company",
        description: [
            ["What's New", "https://blog.mangatv.shop/category/manga-tv/ "],
            ["Feedback", "/feedback"],
            ["FAQ", "/faq"],
        ],
    },
    {
        title: "Social",
        description: [
            ["Telegram", "https://t.me/maxsoft_chat_gpt_group"],
            ["Youtube", "https://www.youtube.com/@MangaTVShop"],
            ["TikTok", "https://www.tiktok.com/@mangatvshop"],
        ],
    },
    {
        title: "Legal",
        description: [
            ["Privacy policy", "/privacy"],
            ["Terms of use", "/terms"],
        ],
    },
];

const Buy = () => {
    const { t } = useTranslation();
    const user = useContext(UserContext);

    const tiers = (user) => [
        {
            title: t("title1"),
            price: "0",
            description: [t("body11"), t("free_minutes"), t("body16"), t("body13"), "GPT-3.5, SD XL", t("body14")],
            buttonText: user ? t("new") : t("body15"),
            buttonVariant: "outlined",
            href: user ? "/story" : "/signup",
        },
        {
            title: t("title21"),
            price: "9.99",
            description: [t("body211"), t("onetime"), t("body26"), t("body23"), "GPT-3.5, SD XL, SVD 1.1", t("body24")],
            buttonText: t("body25"),
            buttonVariant: "outlined",
            href: user ? "/checkout/0" : "/signup",
        },
        {
            title: t("title2"),
            strikedOutPrice: user?.referrer ? "24.99" : null,
            price: user?.referrer ? "19.99" : "24.99",
            description: [
                t("body21"),
                t("onetime"),
                t("body26"),
                t("body33"),
                "GPT-4, Claude, SD 3, SVD 1.1",
                t("body24"),
            ],
            buttonText: t("body25"),
            buttonVariant: "contained",
            href: user ? "/checkout/1" : "/signup",
        },
        {
            title: t("title3"),
            strikedOutPrice: user?.referrer ? "99.99" : null,
            price: user?.referrer ? "79.99" : "99.99",
            description: [
                t("body31"),
                t("onetime"),
                t("body26"),
                t("body33"),
                "GPT-4, Claude, SD 3, SVD 1.1",
                t("body24"),
            ],
            buttonText: t("body25"),
            buttonVariant: "outlined",
            href: user ? "/checkout/2" : "/signup",
        },
    ];

    return (
        <>
            <Helmet>
                <title>Manga TV - Prices</title>
            </Helmet>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }} />
            <CssBaseline />
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                    {t("pricing")}
                </Typography>
                <Typography sx={{ mx: 3 }} variant="h5" align="center" color="text.secondary" component="p">
                    {t("select")}
                </Typography>
            </Container>
            <Container maxWidth="lg" component="main">
                <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
                    {tiers(user).map((tier, index) => (
                        <Grid item key={tier.title} xs={10} sm={6} md={3}>
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: "center" }}
                                    action={
                                        index === 0 ? (
                                            <Tooltip
                                                arrow
                                                enterTouchDelay={0}
                                                title={t("eligible")}
                                                placement="top-start"
                                            >
                                                <InfoIcon color="primary" sx={{ ml: 2, mt: 1 }} />
                                            </Tooltip>
                                        ) : null
                                    }
                                    subheaderTypographyProps={{
                                        align: "center",
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === "light"
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "baseline",
                                            mb: 2,
                                        }}
                                    >
                                        <Typography
                                            sx={{ textDecoration: "line-through" }}
                                            variant="h4"
                                            color="text.secondary"
                                        >
                                            {tier.strikedOutPrice}
                                        </Typography>
                                        <br />
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            ${tier.price}
                                        </Typography>

                                        <Typography variant="h6" color="text.secondary">
                                            {tier.period}
                                        </Typography>
                                    </Box>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        component={RouterLink}
                                        to={tier.href}
                                        variant={tier.buttonVariant}
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">
                    {footers.map((footer) => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            <Typography variant="h6" color="text.primary" gutterBottom>
                                {footer.title}
                            </Typography>
                            <ul>
                                {footer.description.map((item) => (
                                    <li key={item[0]}>
                                        <Link
                                            component={RouterLink}
                                            to={item[1]}
                                            variant="subtitle1"
                                            color="text.secondary"
                                        >
                                            {item[0]}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </>
    );
};

export default Buy;
