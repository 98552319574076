import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";

export function TemperatureSelector({ temperature, onChange }) {
    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="temperature" />
                <Select variant="outlined" value={temperature} onChange={(e) => onChange(e.target.value)}>
                    <MenuItem value={0.1}>Boring</MenuItem>
                    <MenuItem value={0.5}>Normal</MenuItem>
                    <MenuItem value={0.9}>Crazy</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
