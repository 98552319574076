import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";

export const effects = {
    No: "",
    "Zoom&Pan":
        "zoompan=z='min(zoom+0.002,1.3)':d=700:x='if(gte(zoom,1.3),x,x+(iw-ow)/2/a)':y='if(gte(zoom,1.3),y,y+(ih-oh)/2)':s=768x448,setsar=1:1",
    "Zoom&Pan Vertical":
        "zoompan=z='min(zoom+0.002,1.3)':d=700:x='if(gte(zoom,1.3),x,x+(iw-ow)/2/a)':y='if(gte(zoom,1.3),y,y+(ih-oh)/2)':s=448x768,setsar=1:1",
    "Photo Frame": "rotate=angle=-20*PI/180:fillcolor=brown",
    Vignette: "vignette=angle=PI/4",
    "Sepia Tone": "colorchannelmixer=.393:.769:.189:0:.349:.686:.168:0:.272:.534:.131",
    "Saturation Adjustment": "eq=saturation=1.5",
    "Grain Overlay": "noise=alls=30:allf=t+u",
    Pixelizor: "frei0r=filter_name=pixeliz0r:filter_params=0.02|0.02",
    Distort: "frei0r=filter_name=distort0r:filter_params=0.01|0.01",
};

export const EffectSelector = ({ effect, onChange, vertical }) => {
    let eff = { ...effects };
    if (vertical) {
        delete eff["Zoom&Pan"];
    } else {
        delete eff["Zoom&Pan Vertical"];
    }
    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="effect" />
                <Select variant="outlined" value={effect} onChange={(e) => onChange(e.target.value)}>
                    {Object.keys(eff).map((effect) => (
                        <MenuItem key={effect} value={effects[effect]}>
                            {effect}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};
