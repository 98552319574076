import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Typography,
    Container,
    TextField,
    Button,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    IconButton,
    CardMedia,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { API, UserContext } from "../App";
import { useParams } from "react-router-dom";
import moment from "moment";
import Linkify from "linkify-react";

const Comments = ({ admin }) => {
    const { id } = useParams();
    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState([]);
    const [story, setStory] = useState();
    const user = useContext(UserContext);

    useEffect(() => {
        const fetchStoryAndComments = async () => {
            if (id) {
                const storyResponse = await fetch(API + "/story/" + id);
                const storyData = await storyResponse.json();
                setStory(storyData.story);
                const commentsResponse = await fetch(`${API}/stories/${id}/comments`);
                const commentsData = await commentsResponse.json();
                setComments(commentsData);
            }
        };

        fetchStoryAndComments();
    }, [id]);

    const submitComment = async () => {
        if (newComment.trim()) {
            const response = await fetch(`${API}/stories/${id}/comments`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    text: newComment.trim(),
                    user: user?._id,
                }),
            });
            const data = await response.json();
            setComments([data, ...comments]);
            setNewComment("");
        }
    };

    const deleteComment = async (commentId) => {
        await fetch(`${API}/comments/${commentId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
        });
        setComments(comments.filter((comment) => comment._id !== commentId));
    };

    return (
        <Box bgcolor="background.default" minHeight="100vh" py={4}>
            <Container maxWidth="lg">
                <CardMedia
                    component={story?.script?.[1]?.image?.substr(-4) === ".mp4" ? "video" : "img"}
                    height="200"
                    sx={{
                        mt: 1,
                        mb: 1,
                    }}
                    src={API + story?.script?.[1]?.image}
                    autoPlay
                    muted
                />

                <Box mb={4}>
                    <Typography variant="h4" color="text.primary" gutterBottom>
                        {story?.setting}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Share your thoughts and discuss: {story?.description}
                    </Typography>
                </Box>

                <Box mb={4}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="Write a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={submitComment} sx={{ mt: 2 }}>
                        Submit
                    </Button>
                </Box>

                <Box>
                    <Typography variant="h5" color="text.primary" gutterBottom>
                        Comments
                    </Typography>
                    <List>
                        {comments?.length > 0 &&
                            comments?.map((comment) => (
                                <React.Fragment key={comment._id}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar
                                                src={
                                                    comment?.user?.profileUrl ??
                                                    `https://api.dicebear.com/5.x/avataaars/svg?seed=${comment?.user?._id}`
                                                }
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="subtitle1"
                                                        color="text.primary"
                                                    >
                                                        {comment?.user?.firstName}
                                                    </Typography>{" "}
                                                    <Typography
                                                        component="span"
                                                        variant="caption"
                                                        color="text.secondary"
                                                    >
                                                        {moment(comment.createdAt).fromNow()}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                            secondary={
                                                <Linkify
                                                    options={{
                                                        target: "_blank",
                                                        rel: "noopener noreferrer",
                                                    }}
                                                >
                                                    <Typography component="span" variant="body2" color="text.primary">
                                                        {comment.text}
                                                    </Typography>
                                                </Linkify>
                                            }
                                        />
                                        {admin && (
                                            <IconButton onClick={() => deleteComment(comment._id)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        )}
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            ))}
                    </List>
                </Box>
            </Container>
        </Box>
    );
};

export default Comments;
