import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../UI/Copyright";
import { API } from "./../App";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const cookies = new Cookies();

const SignIn = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        setError("");
        try {
            const response = await fetch(`${API}/signin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: data.get("email"),
                    password: data.get("password"),
                }),
            });
            const json = await response.json();
            if (!response.ok) {
                setError(json.message);
                return;
            }
            if (json.token) {
                cookies.set("TOKEN", json.token, {
                    path: "/",
                    maxAge: 30 * 24 * 3600,
                });
                window.location.href = "/story";
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Grid container justifyContent="center">
                    <Avatar sx={{bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography sx={{ m: 1}} component="h1" variant="h5">
                        {t("signin")}
                    </Typography>
                </Grid>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t("email")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t("password")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <br></br>
                    <Grid container justifyContent="center">
                        {loading && <CircularProgress color="primary" />}
                    </Grid>
                    {error && (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    )}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        {t("signin")}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link component={RouterLink} to="/forgot" variant="body2">
                                {t("forgot")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link component={RouterLink} to="/signup" variant="body2">
                                {t("dont")}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright />
        </Container>
    );
};

export default SignIn;
