import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { API } from "../App";
import { Skeleton } from "@mui/material";

export function StyleCard({ setting, image, deleteSetting }) {
    return (
        <Grid item key={setting} xs={12} sm={6} md={4}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                {image ? (
                    <CardMedia component="img" height="200" width="320" image={API + image} />
                ) : (
                    <Skeleton variant="rectangular" height={200} />
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {setting}
                    </Typography>
                </CardContent>
                <CardActions>
                    {deleteSetting && (
                        <Button size="small" onClick={deleteSetting}>
                            Delete
                        </Button>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
}
