import { useEffect, useState } from "react";
import { Autocomplete, Box, Container, Grid, Pagination, TextField } from "@mui/material";
import { API } from "../App";
import StoriesList from "./StoriesList";
import { useTranslation } from "react-i18next";
import { styles } from "./selectors/SettingSelector";

const PAGE_SIZE = 100;

const Search = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [stories, setStories] = useState([]);
    const [results, setResults] = useState(0);
    const [page, setPage] = useState(1);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (inputValue === undefined || inputValue?.length === 0) {
                return;
            }
            const response = await fetch(
                `${API}/search?text=${encodeURIComponent(inputValue.replace(value, ""))}&setting=${value}&from=${
                    results > PAGE_SIZE ? (page - 1) * PAGE_SIZE : 0
                }&size=${PAGE_SIZE}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            setStories(data?.stories);
            setResults(data?.results);
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [inputValue, page, results, timer, value]);

    const reload = () => {
        setTimer(new Date());
    };

    return (
        <>
            <Container maxWidth="lg">
                <Box>
                    <Grid sx={{ my: 1 }} container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Autocomplete
                                value={value}
                                autoComplete
                                fullWidth
                                freeSolo
                                onChange={(event, value) => {
                                    setValue(value);
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, value) => {
                                    setInputValue(value);
                                }}
                                options={styles}
                                renderInput={(params) => <TextField autoFocus {...params} label={t("searchStories")} />}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {results > PAGE_SIZE && (
                    <Pagination
                        count={Math.ceil(results / PAGE_SIZE)}
                        page={page}
                        onChange={(event, value) => {
                            setPage(value);
                        }}
                        color="primary"
                    />
                )}
            </Container>
            <StoriesList stories={stories} reload={reload} />
        </>
    );
};

export default Search;
