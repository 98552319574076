import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography, Rating } from "@mui/material";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { API } from "../App";

const Feedback = () => {
    const [rating, setRating] = useState(0);
    const [suggestion, setSuggestion] = useState("");
    const navigate = useNavigate();

    const handleRatingChange = (event, value) => {
        setRating(value);
    };

    const handleSuggestionChange = (event) => {
        setSuggestion(event.target.value);
    };

    const handleSubmit = () => {
        if (!rating && !suggestion) {
            return;
        }

        fetch(API + "/feedback", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                rating: rating,
                suggestion: suggestion,
            }),
        });
        setRating(0);
        setSuggestion("");
        swal("Thank you for your feedback!", "We will take your feedback into consideration.", "success");
        navigate("/");
    };

    return (
        <Container maxWidth="sm">
            <Box my={4}>
                <Typography variant="h4" component="h1" align="center">
                    Your Feedback
                </Typography>
                <Box mt={3} textAlign="center">
                    <Typography variant="subtitle1">How would you rate our service?</Typography>
                    <Rating name="rating" value={rating} onChange={handleRatingChange} size="large" />
                    <Box mt={3}>
                        <TextField
                            label="Your feature requests, suggestions, comments, or concerns"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={suggestion}
                            onChange={handleSuggestionChange}
                        />
                    </Box>
                    <Box mt={3}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default Feedback;
