import ShareIcon from "@mui/icons-material/Share";
import { IconButton } from "@mui/material";
import ReactGA from "react-ga4";

export const Share = ({ setting, description, url }) => {
    return (
        <IconButton
            onClick={async () => {
                if (!navigator.share) return;
                navigator
                    .share({
                        title: setting,
                        text: description,
                        url: url,
                    })
                    .catch(() => {});
                ReactGA.event({
                    category: "user",
                    action: "share",
                    value: 0,
                });
            }}
        >
            <ShareIcon />
        </IconButton>
    );
};
