import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Suspense, createContext, lazy, useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CssBaseline from "@mui/material/CssBaseline";
import Gallery from "./main/Gallery";
import Layout from "./Layout/Layout";
import Cookies from "universal-cookie";
import ReactGA from "react-ga4";
import Stories from "./main/Stories";
import Loading from "./UI/Loading";
import Feedback from "./UI/Feedback";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import Forgot from "./auth/Forgot";
import Reset from "./auth/Reset";
import Search from "./main/Search";
import Terms from "./payment/Terms";
import Privacy from "./payment/Privacy";
import Buy from "./payment/Buy";
import Account from "./main/Account";
import Custom from "./main/Custom";
import Checkout from "./payment/Checkout";
import Creator from "./main/Creator";
import User from "./main/User";
import FAQ from "./payment/FAQ";
import Webtoon from "./main/Webtoon";
import Comments from "./main/Comments";
import UserVoices from "./main/UserVoices";

const Dashboard = lazy(() => import("./Dashboard/Dashboard"));

export const API =
    process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_ENV === "staging"
            ? "https://staging.mangatv.shop/api"
            : process.env.REACT_APP_ENV === "google"
            ? "https://manga.maxsoft.tk/api"
            : "https://mangatv.shop/api"
        : "http://localhost:5000";

export const UserContext = createContext(null);
ReactGA.initialize("G-YXYXTK9CS8");

const App = () => {
    const [user, setUser] = useState();
    const token = new Cookies().get("TOKEN");

    useEffect(() => {
        const fetchData = async () => {
            if (!token) {
                setUser(null);
                return;
            }
            const data = await fetch(
                API + "/user?lang=" + ((navigator.languages && navigator.languages[0]) || navigator.language),
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const json = await data.json();
            if (json && data.status === 200) {
                setUser({ ...json, token });
            } else {
                setUser(null);
            }
        };

        fetchData().catch(() => {});
    }, [token]);

    return (
        <GoogleOAuthProvider clientId="22302832786-ia3o6bblilpnmveiiteahv6na7b43ogs.apps.googleusercontent.com">
            <UserContext.Provider value={user}>
                <BrowserRouter>
                    <Layout>
                        <Suspense fallback={<Loading />}>
                            <CssBaseline />
                            <Routes>
                                <Route path="/story/:id" element={<Creator />} />
                                <Route path="/story" default element={<Creator />} />
                                <Route path="/signin" element={<SignIn />} />
                                <Route path="/signup" element={<SignUp />} />
                                <Route path="/forgot" element={<Forgot />} />
                                <Route path="/reset/:token" element={<Reset />} />
                                <Route path="/signup/:referrer" element={<SignUp />} />
                                <Route path="/buy" element={<Buy />} />
                                <Route path="/checkout/:id" element={<Checkout />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/faq" element={<FAQ />} />
                                <Route path="/terms" element={<Terms />} />
                                <Route path="/feedback" element={<Feedback />} />
                                <Route path="/search" element={<Search />} />
                                <Route path="/account" element={<Account />} />
                                <Route path="/custom" element={<Custom />} />
                                <Route path="/voices" element={<UserVoices />} />
                                <Route path="/user/:userId" element={<User />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/stories/private" element={<Stories nodedup type={"private"} />} />
                                <Route path="/stories/featured" element={<Stories type="featured" />} />
                                <Route path="/stories/filtered" element={<Stories nodedup type="filtered" />} />
                                <Route path="/stories/duplicated" element={<Stories nodedup type="duplicated" />} />
                                <Route path="/stories/short" nodedup element={<Stories nodedup type="short" />} />
                                <Route path="/stories/plain" nodedup element={<Stories nodedup type="plain" />} />
                                <Route
                                    path="/stories/plain/:newCount"
                                    nodedup
                                    element={<Stories nodedup type="plain" />}
                                />
                                <Route path="/webtoon/:id" element={<Webtoon />} />
                                <Route path="/comments/:id" element={<Comments admin={user?.admin} />} />
                                <Route path="/" element={<Gallery />} />
                                <Route path="*" element={<Gallery />} />
                            </Routes>
                        </Suspense>
                    </Layout>
                </BrowserRouter>
            </UserContext.Provider>
        </GoogleOAuthProvider>
    );
};

export { App };
