import React from "react";

export const WebtoonSlide = ({ caption, image }) => {
    return (
        <div className="slide">
            <div className="slide-caption">{caption}</div>
            {image?.substr(-4) === ".mp4" ? (
                <video className="slide-image" autoPlay src={image} />
            ) : (
                <img className="slide-image" src={image} alt="slide" />
            )}
        </div>
    );
};
