import { IconButton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export const Featured = ({ featured, toggleFeatured }) => {
    return (
        <IconButton sx={{ color: featured ? "yellow" : "" }} onClick={() => toggleFeatured()}>
            <StarIcon />
        </IconButton>
    );
};
