import React, { useState, useEffect, useContext } from "react";
import { API, UserContext } from "./../App";
import {
    Container,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    Tooltip,
    Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import swal from "sweetalert";

const UserVoices = () => {
    const [voices, setVoices] = useState([]);
    const [newVoiceName, setNewVoiceName] = useState("");
    const [newVoiceId, setNewVoiceId] = useState("");
    const user = useContext(UserContext);

    useEffect(() => {
        const fetchVoices = async () => {
            const response = await fetch(API + `/voices`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            });
            const data = await response.json();
            setVoices(data);
        };
        if (user?.token) {
            fetchVoices();
        }
    }, [user?.token]);

    const addVoice = async () => {
        if (!user?.token) {
            return;
        }
        const response = await fetch(API + `/voices`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
            body: JSON.stringify({ name: newVoiceName, voiceId: newVoiceId }),
        });

        if (response.ok) {
            const newVoice = await response.json();
            setVoices([...voices, newVoice]);
            setNewVoiceName("");
            setNewVoiceId("");
        } else {
            const data = await response.json();
            swal("Error", data.message, "error");
        }
    };

    const deleteVoice = async (id) => {
        const response = await fetch(API + `/voices/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${user?.token}`,
            },
        });

        if (response.ok) {
            setVoices(voices.filter((voice) => voice._id !== id));
        } else {
            const data = await response.json();
            swal("Error", data.message, "error");
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography sx={{ mt: 5 }} component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                My Voices
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                Manage your Eleven Labs voices
                <Tooltip
                    arrow
                    enterTouchDelay={0}
                    title="Only available for Silver and Gold plans"
                    placement="top-start"
                >
                    <InfoIcon color="primary" sx={{ ml: 2, mt: 1 }} />
                </Tooltip>
            </Typography>

            <TextField
                label="Voice Name"
                value={newVoiceName}
                onChange={(e) => setNewVoiceName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Voice ID"
                value={newVoiceId}
                onChange={(e) => setNewVoiceId(e.target.value)}
                fullWidth
                margin="normal"
            />
            <Button sx={{ mt: 2 }} variant="contained" color="primary" onClick={addVoice}>
                Add Voice
            </Button>
            <Link sx={{ m: 3 }} target="_blank" component={RouterLink} to="https://elevenlabs.io/app/voice-lab">
                Go to Voice Lab
            </Link>
            <List>
                {voices?.map((voice) => (
                    <React.Fragment key={voice._id}>
                        <ListItem>
                            <ListItemText icon primary={voice.name} secondary={voice.voiceId} />
                            <Button variant="outlined" color="secondary" onClick={() => deleteVoice(voice._id)}>
                                Delete
                            </Button>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </Container>
    );
};

export default UserVoices;
