import NavigationItems from "./NavigationItems";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box } from "@mui/material";

const SideDrawer = ({ open, onDrawer }) => {
    const toggleDrawer = (o) => (event) => {
        if (event?.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        onDrawer(o);
    };

    return (
        <div>
            <SwipeableDrawer anchor="left" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                <Box
                    sx={{ width: 220 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <NavigationItems />
                </Box>
            </SwipeableDrawer>
        </div>
    );
};

export default SideDrawer;
