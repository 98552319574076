import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import * as Sentry from "@sentry/react";
import "./i18n";

// if (process.env.NODE_ENV === "production") {
//     Sentry.init({
//         dsn: "https://2a1118d7b8e54a1399d8efdd43144fe1@o383246.ingest.sentry.io/4504813030277120",
//         sampleRate: 1.0,
//         tracesSampleRate: 0.1,
//         replaysSessionSampleRate: 0.01,
//         replaysOnErrorSampleRate: 0.1,
//     });
// }

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(<App />);

serviceWorkerRegistration.register();
