import { Card, CardContent, Grid } from "@mui/material";
import { Container } from "@mui/material";
import { Typography } from "@mui/material/";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { API, UserContext } from "../App";
import { balance } from "./Account";
import StoriesList from "./StoriesList";
import { getCountryName } from "../Dashboard/countries";
import InfoIcon from "@mui/icons-material/Info";

const User = () => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const [user, setUser] = useState();
    const [count, setCount] = useState(0);
    const [stories, setStories] = useState([]);
    const adminUser = useContext(UserContext);

    useEffect(() => {
        if (userId && adminUser?.token) {
            fetch(API + "/user/" + userId, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${adminUser?.token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setUser(data);
                });

            fetch(API + "/stories/user/" + userId, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${adminUser?.token}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setStories(data);
                });
        }
    }, [adminUser?.token, userId, count]);

    const reload = () => {
        setCount(count + 1);
    };

    if (!user?.email) {
        return null;
    }

    return (
        <>
            <Container sx={{ py: 3 }} maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2">{user?.firstName}</Typography>
                                <Typography variant="h4">{user?.lastName}</Typography>
                                <Typography variant="h6">{user?.email}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {user?.referral && (
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h4">{t("referral")}</Typography>
                                    <Typography variant="h2">{user?.referral}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                background:
                                    user?.plan === "gold"
                                        ? "gold"
                                        : user?.plan === "silver"
                                        ? "silver"
                                        : user?.plan === "bronze"
                                        ? "#CD7F32"
                                        : "",
                            }}
                        >
                            <CardContent>
                                <Typography variant="h4">{t("plan")}</Typography>
                                <Typography variant="h2">{user?.plan?.toUpperCase() ?? "FREE"}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">{t("balance")}</Typography>
                                <Typography variant="h2">{balance(user)}</Typography>
                                {!user?.plan && !user?.locked && (
                                    <Grid container justifyContent="left">
                                        <InfoIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="body2">{t("free_minutes")}</Typography>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">{t("lang")}</Typography>
                                <Typography variant="h2">{user?.lang}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">{t("Country")}</Typography>
                                <Typography variant="h2">{getCountryName(user?.country) ?? user?.country}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">User Agent</Typography>
                                <Typography>{user?.userAgent}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">Created</Typography>
                                <Typography variant="h6">{new Date(user?.createdAt).toLocaleString()}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {user?.locked && (
                        <Grid item xs={12} md={6}>
                            <Card sx={{ background: "red" }}>
                                <CardContent>
                                    <Typography variant="h2">LOCKED</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                <StoriesList stories={stories} reload={reload} />
            </Container>
        </>
    );
};

export default User;
