import { API } from "../App";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WebtoonSlide } from "./WebtoonSlide";

const Webtoon = () => {
    const { id } = useParams();
    const [script, setScript] = useState([]);

    useEffect(() => {
        if (id) {
            fetch(API + "/story/" + id)
                .then((response) => response.json())
                .then((data) => {
                    const story = data.story;
                    if (story) {
                        setScript(story.script);
                    }
                })
                .catch((err) => {});
        }
    }, [id]);

    return (
        <div className="slide-container">
            {script.map((slide, index) => (
                <WebtoonSlide key={index} caption={slide.caption} image={slide.image && API + slide.image} />
            ))}
        </div>
    );
};

export default Webtoon;
