import {
    Box,
    Button,
    CardMedia,
    CircularProgress,
    Grid,
    IconButton,
    Skeleton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useState } from "react";
import Audiotrack from "@mui/icons-material/Audiotrack";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import InfoIcon from "@mui/icons-material/Info";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";
import { useTranslation } from "react-i18next";
import ImageUpload from "../UI/ImageUpload";

const Slide = ({
    caption,
    image,
    setName,
    repaintImage,
    convertToVideo,
    uploadImage,
    deleteSlide,
    listenAudio,
    time,
    splitCell,
    vertical,
}) => {
    const { t } = useTranslation();
    const [pictureLoading, setPictureLoading] = useState(false);
    const [videoLoading, setVideoLoading] = useState(false);
    const [pressed, setPressed] = useState(false);

    return (
        <>
            <Grid sx={{ my: 3 }} item xs={6}>
                <TextField
                    label="Step"
                    fullWidth
                    value={caption}
                    onChange={(e) => setName(e.target.value)}
                    multiline
                    rows={10}
                />
                <Button variant="outlined" color="secondary" onClick={() => deleteSlide()}>
                    {t("delete")}
                </Button>
                <IconButton color="primary" onClick={() => listenAudio()}>
                    <Audiotrack />
                </IconButton>
                <IconButton
                    sx={{
                        mx: -1,
                        transform: "scaleY(-1)",
                    }}
                    color="secondary"
                    onClick={() => splitCell()}
                >
                    <AltRouteIcon />
                </IconButton>
            </Grid>
            <Grid sx={{ my: 3 }} item xs={6}>
                <>
                    <Box sx={{ position: "relative" }}>
                        {image ? (
                            <>
                                <CardMedia
                                    component={image?.substr(-4) === ".mp4" ? "video" : "img"}
                                    height="260"
                                    src={image}
                                    autoPlay
                                    muted
                                />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: 0,
                                        width: "100%",
                                        bgcolor: "rgba(0, 0, 0, 0.24)",
                                        color: "white",
                                        padding: "5px",
                                    }}
                                >
                                    <Typography variant="body2">{time}</Typography>
                                </Box>
                            </>
                        ) : (
                            <Skeleton variant="rectangular" height={194} />
                        )}
                    </Box>
                    <Grid container>
                        <Box sx={{ position: "relative" }}>
                            <Button
                                disabled={pictureLoading}
                                color="primary"
                                variant="outlined"
                                onClick={async () => {
                                    setPressed(true);
                                    setPictureLoading(true);
                                    try {
                                        await repaintImage();
                                    } finally {
                                        setPictureLoading(false);
                                    }
                                }}
                            >
                                {t("repaint")}
                            </Button>
                            {pictureLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Box>
                        <ImageUpload vertical={vertical} onUpload={(image) => uploadImage(image)} />
                        <Box sx={{ position: "relative" }}>
                            <Button
                                disabled={videoLoading}
                                color="primary"
                                variant="outlined"
                                startIcon={<FlashAutoIcon />}
                                onClick={async () => {
                                    if (!image || image.includes("filter")) {
                                        return;
                                    }
                                    setVideoLoading(true);
                                    try {
                                        await convertToVideo();
                                    } finally {
                                        setVideoLoading(false);
                                    }
                                }}
                            >
                                {t("SVD 1.1")}
                            </Button>
                            {videoLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Box>
                        {pressed && (
                            <Tooltip arrow enterTouchDelay={0} title={t("blurred")} placement="top-start">
                                <InfoIcon color="primary" sx={{ ml: 2, mt: 1 }} />
                            </Tooltip>
                        )}
                    </Grid>
                </>
            </Grid>
        </>
    );
};

export default Slide;
