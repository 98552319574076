import Box from "@mui/material/Box";
import { Button, CircularProgress, Grid } from "@mui/material";
import { green } from "@mui/material/colors";

export const ProgressButton = ({ loading, innerRef, onClick, title, success, disabled, sx }) => {
    const styles = {
        ...(success && {
            bgcolor: green[500],
            "&:hover": {
                bgcolor: green[700],
            },
        }),
    };

    return (
        <Grid sx={sx} container justifyContent="center">
            <Box sx={{ position: "relative" }}>
                <Button
                    disabled={disabled}
                    ref={innerRef}
                    variant="contained"
                    sx={styles}
                    color="secondary"
                    onClick={onClick}
                >
                    {title}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Box>
        </Grid>
    );
};
