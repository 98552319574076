import AdSense from "react-adsense";

const FeedAd = ({ dark }) => (
    <AdSense.Google
        client="ca-pub-1351673544337847"
        slot={"2077483287"}
        style={{ display: "block", width: "370px" }}
        format="fluid"
        responsive="true"
        layoutKey={"-2z+cv+28-kx+qu"}
    />
);
export default FeedAd;
