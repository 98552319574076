import { IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

export const Like = ({ liked, setLiked, sendLike }) => {
    const onClick = () => {
        if (!liked) {
            sendLike();
        }
        setLiked(true);
    };

    return (
        <IconButton sx={{ color: liked ? "red" : "" }} onClick={onClick}>
            <FavoriteIcon />
        </IconButton>
    );
};
