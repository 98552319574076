import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";

const models = [
    "gpt-3.5-turbo",
    "gpt-4-turbo",
    "gpt-4o",
    "gpt-4o-mini",
    "o1-mini",
    "gemini-1.5-pro",
    "mistral-medium",
    "mistral-large-latest",
    "llama-3-70b-chat",
    "anthropic.claude-v2:1",
    "anthropic.claude-v2",
    "claude-3-sonnet-20240229",
    "claude-3-5-sonnet-20240620",
    "claude-3-opus-20240229",
    "claude-3-haiku-20240307",
];

export function TextModelSelector({ model, onChange }) {
    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="model" />
                <Select variant="outlined" value={model} onChange={(e) => onChange(e.target.value)}>
                    {models?.map((model) => (
                        <MenuItem key={model} value={model}>
                            {model}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
