import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export function Copyright() {
    return (
        <>
            <Typography sx={{ my: 4 }} variant="body2" color="text.secondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://blog.mangatv.shop/">
                    MaxSoft
                </Link>{" "}
                {new Date().getFullYear()}
                {". "}
                <Link color="inherit" component={RouterLink} to="/faq">
                    FAQ
                </Link>{" "}
                <Link color="inherit" component={RouterLink} to="/terms">
                    Terms
                </Link>{" "}
                <Link color="inherit" component={RouterLink} to="/privacy">
                    Privacy
                </Link>
            </Typography>
        </>
    );
}
