import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { StoryCard } from "./StoryCard";
import { API, UserContext } from "../App";
import { Fragment, useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import useInfinite from "../UI/useInfinite";
import { useTranslation } from "react-i18next";
import FeedAd from "../UI/FeedAd";
import { isTablet } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../UI/Loading";
import { Box, Typography } from "@mui/material";
import maintenanceImage from "../assets/maintenance.webp";

const Stories = ({ my, nodedup, type }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { newCount } = useParams();
    const [stories, setStories] = useState();
    const [margin] = useInfinite(8, 0.75, 4);
    const user = useContext(UserContext);
    const [count, setCount] = useState(0);
    const [maintenance, setMaintenance] = useState(false);
    const [playingCardId, setPlayingCardId] = useState(null);

    const playClick = (cardId) => {
        setPlayingCardId(cardId);
    };

    useEffect(() => {
        fetch(
            API +
                (type
                    ? "/stories/" + type
                    : my
                    ? "/mystories"
                    : "/stories?lang=" +
                      ((navigator.languages && navigator.languages[0]) ||
                          navigator.language ||
                          navigator.browserLanguage ||
                          navigator.userLanguage ||
                          navigator.systemLanguage ||
                          null)),
            {
                headers: {
                    Authorization: `Bearer ${user?.token}`,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (newCount && newCount > 0) {
                    data = data.slice(0, newCount);
                    window?.localStorage?.setItem("lastStory", data[0].createdAt);
                }
                setStories(data);
            })
            .catch((err) => {
                setMaintenance(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [my, count, newCount, type]);

    const deleteStory = (id) => {
        swal({
            title: t("deleteStory"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                const result = await fetch(API + "/story", {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                });
                if (result.status === 200) {
                    setCount(count + 1);
                    swal(t("done"), t("storyDeleted"), "success");
                }
            }
        });
    };

    const markAppropriate = (id) => {
        if (!user?.admin) {
            return;
        }
        swal({
            title: t("mark"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const response = await fetch(API + "/story/" + id, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ inappropriate: false }),
                });
                if (response.status === 200) {
                    setCount(count + 1);
                    swal(t("done"), t("marked"), "success");
                }
            }
        });
    };

    const markUploaded = (id) => {
        if (!user?.admin) {
            return;
        }
        swal({
            title: t("markUploaded"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const response = await fetch(API + "/story/" + id, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ uploaded: true }),
                });
                if (response.status === 200) {
                    setCount(count + 1);
                }
            }
        });
    };

    const markFeatured = async (id, featured) => {
        fetch(API + "/story/" + id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
            body: JSON.stringify({ featured: featured }),
        });
    };

    const publishStory = (id) => {
        swal({
            title: t("publish"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const result = await fetch(API + "/publish/" + id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                });
                if (result.status === 200) {
                    setCount(count + 1);
                    swal(t("done"), t("published"), "success");
                } else {
                    swal(t("error"), (await result.json()).message, "error");
                }
            }
        });
    };

    const openUser = (u) => {
        if (!user?.admin) {
            return;
        }
        navigate("/user/" + u);
    };

    if (maintenance)
        return (
            <Container sx={{ py: 3 }} maxWidth="lg">
                <Box sx={{ textAlign: "center" }}>
                    <img src={maintenanceImage} alt="Maintenance. Please come later ❤️" width={300} />
                </Box>
            </Container>
        );
    if (!stories || !Array.isArray(stories)) {
        return <Loading />;
    }
    return (
        <Container sx={{ py: 3 }} maxWidth="xl">
            {stories?.length === 0 && (
                <Typography component="h4" variant="h4" align="center">
                    No stories
                </Typography>
            )}
            <Grid container spacing={4}>
                {stories //deduplicate stories using reduce(), for each description keep the one with bigger createdAt
                    ?.reduce((acc, card) => {
                        const found = acc.find((c) => c.description === card.description);
                        if (!found || nodedup) {
                            acc.push(card);
                        } else if (found.createdAt < card.createdAt) {
                            acc.splice(acc.indexOf(found), 1, card);
                        }
                        return acc;
                    }, [])
                    ?.filter((card) => card.video)
                    ?.slice(0, margin)
                    ?.map((card, index) => (
                        <Fragment key={card._id}>
                            <StoryCard
                                admin={user?.admin}
                                card={card}
                                deleteStory={deleteStory}
                                publishStory={publishStory}
                                markAppropriate={markAppropriate}
                                markUploaded={markUploaded}
                                markFeatured={markFeatured}
                                openUser={openUser}
                                isPlaying={card._id === playingCardId}
                                onPlayClick={playClick}
                            />
                            {!isTablet && !user?.plan && !user?.admin && index % 10 === 9 && <FeedAd />}
                        </Fragment>
                    ))}
            </Grid>
        </Container>
    );
};

export default Stories;
