import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";
import { useEffect, useState } from "react";
import { API } from "../../App";

export const defaultMusics = [
    "No",
    "alpha-mission-jimena-contreras",
    "anxiety",
    "atlantis-rage-jimena-contreras",
    "aztec-empire-jimena-contreras",
    "chaos",
    "classic-mariachi-jimena-contreras",
    "confident-kurt-tracktribe",
    "coniferous-forest",
    "devil-s-organ-jimena-contreras",
    "dream",
    "fiesta-jarocha-jimena-contreras",
    "flook-wrong-foot-forward",
    "fluffing-a-duck",
    "forest",
    "girasol-quincas-moreira",
    "high-noon-tracktribe",
    "investigations",
    "lawrence-tracktribe",
    "little-fish-quincas-moreira",
    "love-is-calembour",
    "palace-of-roses-aakash-gandhi",
    "relaxing-music",
    "scott-buckley",
    "siestita-quincas-moreira",
    "silence",
    "strollin-tracktribe",
    "underground-academy-hanu-dixit",
    "violin-huasteco-jimena-contreras",
];

export function MusicSelector({ music, onChange, plan }) {
    const [musics, setMusics] = useState(defaultMusics);

    useEffect(() => {
        // if (!plan) {
        //     return;
        // }
        fetch(API + "/music", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json();
                    setMusics(data.music);
                }
            })
            .catch((err) => {});
    }, [plan]);

    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="music" />
                <Select
                    variant="outlined"
                    value={music}
                    onChange={(e) => {
                        const audio = new Audio(API + `/music/${e.target.value}.mp3`);
                        audio.play();
                        setTimeout(() => {
                            audio.pause();
                        }, 5000); 
                        onChange(e.target.value);
                    }}
                >
                    {musics?.map((music) => (
                        <MenuItem key={music} value={music}>
                            {music}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
