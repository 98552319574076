import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { Fab, Grid, Zoom } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import { useContext, useEffect, useState } from "react";
import { API, UserContext } from "../App";
import { StyleCard } from "./StyleCard";
import { animateScroll as scroll } from "react-scroll";

const Custom = () => {
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        fetch(API + "/settings", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setSettings(data.settings);
            });
    }, []);

    const addSetting = () => {
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }
        let input = document.createElement("input");
        input.value = "";
        input.type = "text";
        input.className = "swal-content__input";
        swal("Enter your visual setting, max 30 chars, English:", {
            content: input,
            buttons: [t("cancel"), "OK"],
        }).then((ok) => {
            if (ok) {
                let newSettings = [...settings];
                const setting = {
                    setting: input.value?.substring(0, 30),
                    text: input.value?.substring(0, 30),
                    id: new Date(),
                };
                newSettings.push(setting);
                setSettings(newSettings);
                scroll.scrollToBottom();
                fetch(API + "/setting", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify(setting),
                }).then(async (res) => {
                    if (res.ok) {
                        let data = await res.json();
                        setSettings(data.settings);
                    } else {
                        let error = await res.json();
                        setSettings(settings);
                        swal("Error", error?.message || "Error", "error");
                    }
                });
            }
        });
    };

    const deleteSetting = (id) => {
        swal({
            title: t("sure"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                fetch(API + "/setting", {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                }).then(async (res) => {
                    if (res.ok) {
                        let data = await res.json();
                        setSettings(data.settings);
                    } else {
                        let error = await res.json();
                        swal("Error", error?.message || "Error", "error");
                    }
                });
            }
        });
    };

    return (
        <>
            <Zoom
                in={true}
                timeout={{
                    enter: 300,
                    exit: 300,
                }}
                style={{
                    transitionDelay: "300ms",
                }}
                unmountOnExit
            >
                <Fab
                    sx={{
                        position: "fixed",
                        zIndex: "1000",
                        bottom: 20,
                        right: 20,
                    }}
                    color="secondary"
                    onClick={addSetting}
                    aria-label="add"
                >
                    <AddIcon />
                </Fab>
            </Zoom>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                        {t("custom")}
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        {t("customText")}
                    </Typography>
                </Container>
            </Box>
            <Container sx={{ py: 3 }} maxWidth="lg">
                <Grid container spacing={4}>
                    {settings?.map((setting) => (
                        <StyleCard
                            key={setting._id}
                            setting={setting.setting}
                            image={setting.image}
                            deleteSetting={(user?.plan || user?.admin) && (() => deleteSetting(setting._id))}
                        />
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default Custom;
