import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { InputLabel } from "./InputLabel";
import { useEffect, useState } from "react";
import { API } from "../../App";

export const styles = [
    "Anime/Manga",
    "Comics",
    "StarWars",
    "Marvel Universe",
    "DC Universe",
    "Old Soviet Cartoon",
    "Disney",
    "South Park",
    "The Simpsons",
    "Pokemon",
    "Minecraft",
    "Lego",
    "Business",
    "Photo Realistic",
    "Fine Arts",
    "Watercolor",
];

export function SettingSelector({ setting, onChange, plan }) {
    const [customStyles, setCustomStyles] = useState([]);

    useEffect(() => {
        if (!plan) {
            return;
        }
        fetch(API + "/settings", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json();
                    setCustomStyles(data.settings);
                }
            })
            .catch((err) => {});
    }, [plan]);

    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="setting" />
                <Select variant="outlined" value={setting} onChange={(e) => onChange(e.target.value)}>
                    {styles?.map((style) => (
                        <MenuItem key={style} value={style}>
                            {style}
                        </MenuItem>
                    ))}
                    {plan &&
                        customStyles?.map((style) => (
                            <MenuItem key={style.setting} value={style.setting}>
                                {style.setting}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
