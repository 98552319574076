import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { API } from "./App";

const firebaseConfig = {
    apiKey: "AIzaSyCowlxjJSeP1CdTGFStn54au71vg8-1b0k",
    authDomain: "manga-tv-381714.firebaseapp.com",
    projectId: "manga-tv-381714",
    storageBucket: "manga-tv-381714.appspot.com",
    messagingSenderId: "22302832786",
    appId: "1:22302832786:web:dfd861ea5f72eb46868e4b",
    measurementId: "G-LKFD32RGE8",
};

const app = initializeApp(firebaseConfig);

export function requestPermission(user) {
    try {
        const messaging = getMessaging(app);
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                getToken(messaging, {
                    vapidKey: "BGjl7HqTv1clyy2P9npJ65Fj29g-cXgEoBJWILejRvz6V5rE_xayaUhVHsHvBse12hL4jBVKpKOfMM01ZIVdcds",
                })
                    .then((currentToken) => {
                        if (currentToken) {
                            fetch(`${API}/push/token`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${user?.token}`,
                                },
                                body: JSON.stringify({
                                    pushToken: currentToken,
                                }),
                            });
                        }
                    })
                    .catch((err) => {});
            } else {
            }
        });
    } catch {}
}
