import { useState } from "react";
import AppBar from "./AppBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideDrawer from "./SideDrawer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import CookieConsent from "react-cookie-consent";

const Layout = (props) => {
    const [drawer, setDrawer] = useState(false);

    return (
        <ThemeProvider theme={theme("dark")}>
            <AppBar onDrawer={setDrawer} />
            <SideDrawer onDrawer={setDrawer} open={drawer} />
            <main>{props.children}</main>
            <ToastContainer />
            <CookieConsent
                acceptOnScroll={true}
                location="bottom"
                buttonText="Accept"
                style={{ background: "#333", width: "400" }}
                buttonStyle={{ backgroundColor: "#F50057", color: "white", fontSize: "13px", fontWeight: "bold" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </ThemeProvider>
    );
};

export default Layout;
