import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { StoryCard } from "./StoryCard";
import useInfinite from "../UI/useInfinite";
import { useContext, useState } from "react";
import { API, UserContext } from "../App";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StoriesList = ({ stories, reload }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [margin] = useInfinite(8, 0.75, 4);
    const user = useContext(UserContext);
    const [playingCardId, setPlayingCardId] = useState(null);

    const playClick = (cardId) => {
        setPlayingCardId(cardId);
    };

    const deleteStory = (id) => {
        swal({
            title: t("deleteStory"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                const result = await fetch(API + "/story", {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                });
                if (result.status === 200) {
                    reload();
                    swal(t("done"), t("storyDeleted"), "success");
                }
            }
        });
    };

    const markAppropriate = (id) => {
        if (!user?.admin) {
            return;
        }
        swal({
            title: t("mark"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const response = await fetch(API + "/story/" + id, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ inappropriate: false }),
                });
                if (response.status === 200) {
                    reload();
                    swal(t("done"), t("marked"), "success");
                }
            }
        });
    };

    const markUploaded = (id) => {
        if (!user?.admin) {
            return;
        }
        swal({
            title: t("markUploaded"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const response = await fetch(API + "/story/" + id, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ uploaded: true }),
                });
                if (response.status === 200) {
                    reload();
                }
            }
        });
    };

    const markFeatured = async (id, featured) => {
        fetch(API + "/story/" + id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
            body: JSON.stringify({ featured: featured }),
        });
    };

    const publishStory = (id) => {
        swal({
            title: t("publish"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const result = await fetch(API + "/publish/" + id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                });
                if (result.status === 200) {
                    reload();
                    swal(t("done"), t("published"), "success");
                } else {
                    swal(t("error"), (await result.json()).message, "error");
                }
            }
        });
    };

    const openUser = (u) => {
        if (!user?.admin) {
            return;
        }
        navigate("/user/" + u);
    };

    return (
        <Container sx={{ py: 3 }} maxWidth="xl">
            <Grid container spacing={4}>
                {stories?.slice(0, margin)?.map((card) => (
                    <StoryCard
                        admin={user?.admin}
                        card={card}
                        deleteStory={() => deleteStory(card._id)}
                        publishStory={publishStory}
                        markAppropriate={markAppropriate}
                        markUploaded={markUploaded}
                        markFeatured={markFeatured}
                        openUser={openUser}
                        key={card._id}
                        isPlaying={card._id === playingCardId}
                        onPlayClick={playClick}
                    />
                ))}
            </Grid>
        </Container>
    );
};

export default StoriesList;
