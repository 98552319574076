import Box from "@mui/material/Box";
import { CircularProgress, Grid } from "@mui/material";

const Loading = () => {
    return (
        <Grid container justifyContent="center">
            <Box sx={{ my: 10 }}>
                <CircularProgress sx={{ ml: 2 }} size={100} />
            </Box>
        </Grid>
    );
};

export default Loading;