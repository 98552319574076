import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    color: "#aaa",
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const faq = [
    {
        question: "Is Manga TV subscription-based, or do I prepay?",
        answer: "Manga TV operates on a prepaid model with no subscription commitment. You have the flexibility to choose from our available pricing plans, and your minutes will not expire. If you choose one of our paid options (Bronze, Silver, or Gold), you only pay for the video minutes you use. There are no recurring subscription fees.",
    },
    {
        question: "How are consumed video minutes calculated?",
        answer: "Calculating consumed video minutes is a breeze! We track each AI operation, whether it's text and image generation, translation, or audio creation, and count them separately. These counts are then gracefully converted into credits, and voilà, transformed into video minutes using a fair and average approach. Plus, if you adjust your video by painting several slides or changing music, you'll only lose a fraction of a minute.",
    },
    {
        question: "Is the story I create on the paid plan private?",
        answer: "Yes, the stories created on the paid plan are private, as specified in our Terms p.10. Additionally, users on the paid plan retain full ownership of the intellectual property rights to their videos (and still images), and MangaTV.shop guarantees that these videos (and still images) are royalty-free.",
    },
    {
        question: "Why is the free plan not available for me?",
        answer: "The availability of the free plan is tied to our server load. We want to ensure a smooth and enjoyable experience for all users, and at times, the demand may affect the availability of the free plan. Rest assured, we're constantly working to optimize and enhance our services to make the free plan accessible to as many users as possible.",
    },
    {
        question: "Which of the text models are available?",
        answer: "In the Bronze plan, you have access to GPT-3.5. Moving up to the Silver plan, you can also enjoy GPT-4, Gemini 1.5, Mistral, and Claude 3 Opus. More models will be added in the near future! Stay tuned.",
    },
    {
        question: "Which of the image models are available?",
        answer: "You can choose from the following image models: SD XL, SD 1.5, SD 1.6 (from different providers), Google, and Unsplash. Select the model that best suits your creative vision. Notably, Google and Unsplash models are well-suited for creating documentary, news, and educational videos. For Silver and Gold plans, indulge in the enhanced capabilities of Stable Diffusion 3, Imagen2 and DALL-E3, elevating your creative possibilities even further.",
    },
    {
        question: "Which of the video clip models are available?",
        answer: "Currently, only SVD 1.1 is available, elevating your creative possibilities even further. Sora will be available soon. Additionally, please note that converting an image to a video clip incurs a cost of 1 minute deducted from your balance.",
    },
    {
        question: "Can I upload my own video fragment?",
        answer: "Sure! Now you can upload your own video clips directly to Manga TV, adding a personal touch and enhancing the immersive experience for your readers. If ength of video clip is shorter that sound, it will be looped.",
    },
    {
        question: "Are there any video post-production effects?",
        answer: "Sure! Explore a variety of effects to add style and flair to your video content, making your manga stories even more engaging and visually stunning. Discover effects like 'Zoom&Pan', 'Photo Frame', 'Vignette', 'Sepia Tone', and more, to unleash your creativity and enhance your storytelling experience.",
    },
    {
        question: "Why does Manga TV always create fiction? What if I want educational content?",
        answer: "No worries! If you're aiming for more educational content, simply toggle on the 'include description into story' flag. This handy feature switches off the story mode, letting Manga TV seamlessly continue from your prompt without the narrative touch. It's your way to tailor the experience to suit your educational needs effortlessly.",
    },
    {
        question: "How can I improve my story?",
        answer: "Once your story is complete, try refining it by repainting any less-than-perfect pictures and removing any slides that might not quite hit the mark. After making your tweaks, simply hit the Video button again.",
    },
    {
        question: "Why do I have blurred images?",
        answer: "If you find yourself consistently facing blurred images, consider removing stop words or explicit content from cell text. Also, you can try a different image model. It's worth noting that Manga TV is not designed for adult content, so maintaining a creative and respectful approach ensures the best results for all users.",
    },
    {
        question: "Can I write my own script or generate a story from my own script?",
        answer: "Absolutely! Now, on the paid plan, you have the freedom to paste your own script from the clipboard. Just use the designated button and let your creativity take center stage.",
    },
    {
        question: "What is 'Auto' mode in Story Creator?",
        answer: "In 'Auto' mode, the Creator seamlessly navigates through all stages – from script generation to picture creation and video generation. For those seeking more control, simply switch it off. This grants you the power to tweak the script, refine texts, and recreate images at your own pace. With 'Auto' off, you can manually press 'Pictures' and 'Video' after a hands-on touch to your creative process.",
    },
    {
        question: "How many voices are available in the paid plan?",
        answer: "The paid plan includes all Google WaveNet+Neural2 voices, and on the Silver and Gold plans, you can use six additional OpenAI voices, as well as Studio voices and Eleven Labs voices. Important: Studio and Eleven Labs voices are more expensive and consume 3x more minutes!",
    },
    {
        question: "Can I customize visual settings?",
        answer: "Custom visual settings are available exclusively on the paid plans (Bronze, Silver, and Gold). With these plans, you have the flexibility to tailor the visual elements of your creations, allowing for a more personalized and unique output. Explore the possibilities and enhance your storytelling experience with the freedom to customize visual settings according to your preferences.",
    },
    {
        question: "Can I export my creations as PDFs?",
        answer: "Yes, PDF export functionality is available on all paid plans (Bronze, Silver, and Gold). This feature enables you to conveniently save and share your creations in a PDF format, allowing for easy distribution and preservation of your work.",
    },
    {
        question: "Can I create a very long story with it, like in episodes?",
        answer: "Certainly! There is a Continue button that you can use infinitely. This allows you to create and extend your story across multiple episodes.",
    },
    {
        question: "Can I provide suggestions for future updates or features?",
        answer: "Absolutely! We value user input. Feel free to share your suggestions and ideas, and we'll consider them for future updates to enhance your Manga TV experience.",
    },
];

export default function FAQ() {
    const [expanded, setExpanded] = React.useState("");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 2 }}>
            <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                F.A.Q.
            </Typography>
            {faq.map((row, index) => (
                <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary>
                        <Typography>{row.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{row.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
}
