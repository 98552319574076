import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import { voices } from "./VoiceSelector";
import { InputLabel } from "./InputLabel";

export function LangSelector({ lang, onChange }) {
    return (
        <Grid item xs={6} md={3}>
            <FormControl fullWidth>
                <InputLabel caption="lang" />
                <Select
                    variant="outlined"
                    value={lang}
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                >
                    {voices.map((v) => (
                        <MenuItem key={v.lang} value={v.lang}>
                            {v.name_lang}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
