import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link as RouterLink } from "react-router-dom";

const NavigationItem = ({ link, icon, children, hard }) => (
    <ListItem
        component={RouterLink}
        to={link}
        onClick={() => {
            if (hard) {
                window.location.href = link;
            }
        }}
        button
    >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={children} />
    </ListItem>
);

export default NavigationItem;
