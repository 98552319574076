import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Button, FormControlLabel, Grid, IconButton, Switch, TextField, Typography } from "@mui/material";
import { useState, useEffect, useRef, useContext } from "react";
import Video from "../UI/Video";
import { useParams } from "react-router-dom";
import { API, UserContext } from "../App";
import swal from "sweetalert";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { animateScroll as scroll } from "react-scroll";
import { Share } from "../UI/Share";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ReportIcon from "@mui/icons-material/Report";
import { ProgressButton } from "../UI/ProgressButton";
import { MusicSelector } from "./selectors/MusicSelector";
import { SettingSelector } from "./selectors/SettingSelector";
import { VoiceSelector, voices } from "./selectors/VoiceSelector";
import { LangSelector } from "./selectors/LangSelector";
import { TextModelSelector } from "./selectors/TextModelSelector";
import { TemperatureSelector } from "./selectors/TemperatureSelector";
import { ImageModelSelector, imageModels } from "./selectors/ImageModelSelector";
import { Switches } from "./selectors/Switches";
import { Slides } from "./Slides";
import { TikTokIcon } from "../UI/TikTokIcon";
import Loading from "../UI/Loading";
import jsPDF from "jspdf";
import ReactGA from "react-ga4";
import { requestPermission } from "../firebase";
import { RemoveCircleOutline } from "@mui/icons-material";
import { EffectSelector } from "./selectors/EffectSelector";

const MAX_PARAGRAPH_SIZE = 300;

const Creator = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useContext(UserContext);

    useEffect(() => {
        if (id) {
            fetch(API + "/story/" + id)
                .then((response) => response.json())
                .then((data) => {
                    const story = data.story;
                    if (story) {
                        setVideo(story.video);
                        setDescription(story.description);
                        setLang(story.lang);
                        setVoice(story.voice);
                        setSetting(story.setting);
                        setCreatedAt(story.createdAt);
                        setVertical(story.vertical ?? false);
                        if (story.music) {
                            setMusic(story.music);
                        }
                        setEffect(story.effect);
                        setUserId(story.user);
                        setScript(story.script);
                    } else {
                        navigate("/story");
                    }
                })
                .catch((err) => {});
        }
    }, [id, navigate]);

    const [userId, setUserId] = useState();
    const [storyId, setStoryId] = useState();
    const [script, setScript] = useState([]);
    const [createdAt, setCreatedAt] = useState();
    const [description, setDescription] = useState("");
    const [generatePressed, setGeneratePressed] = useState(false);
    const [video, setVideo] = useState();
    const [lang, setLang] = useState("en-US");
    const [voice, setVoice] = useState("en-US-Standard-C");
    const [setting, setSetting] = useState("Comics");
    const [music, setMusic] = useState("relaxing-music");
    const [autoFlow, setAutoFlow] = useState(true);
    const [includeDesc, setIncludeDesc] = useState(false);
    const [vertical, setVertical] = useState(false);
    const [titles, setTitles] = useState(true);
    const [model, setModel] = useState("gpt-4o-mini");
    const [temperature, setTemperature] = useState(0.5);
    const [imageModel, setImageModel] = useState("Flux Schnell");
    const [effect, setEffect] = useState("");

    useEffect(() => {
        const description = window.localStorage?.getItem("description");
        if (description) setDescription(description);
        const lang = window.localStorage?.getItem("lang");
        if (lang) setLang(lang);
        const voice = window.localStorage?.getItem("voice");
        if (voice) setVoice(voice);
        const setting = window.localStorage?.getItem("setting");
        if (setting) setSetting(setting);
        const music = window.localStorage?.getItem("music");
        if (music) setMusic(music);
        const autoFlow = window.localStorage?.getItem("autoFlow");
        setAutoFlow(!(autoFlow === "false"));
        const vertical = window.localStorage?.getItem("vertical");
        setVertical(vertical === "true");
    }, []);

    useEffect(() => {
        window.localStorage?.setItem("description", description);
        window.localStorage?.setItem("lang", lang);
        window.localStorage?.setItem("voice", voice);
        window.localStorage?.setItem("setting", setting);
        window.localStorage?.setItem("music", music);
        window.localStorage?.setItem("autoFlow", autoFlow);
        window.localStorage?.setItem("vertical", vertical);
    }, [description, lang, setting, voice, music, autoFlow, model, vertical]);

    const videoRef = useRef();
    const picturesRef = useRef();
    const continueRef = useRef();
    const [storyLoading, setStoryLoading] = useState(false);
    const [videoLoading, setVideoLoading] = useState(false);
    const [videoSuccess, setVideoSuccess] = useState(false);
    const [picturesLoading, setPicturesLoading] = useState(false);
    const [picturesSuccess, setPicturesSuccess] = useState(false);
    const [continueLoading, setContinueLoading] = useState(false);

    function splitStoryIntoSlides(story) {
        return story
            .split("\n")
            .map((slide) => slide.trim())
            .filter((slide) => slide !== "" && slide?.length > 10)
            .flatMap((slide) => {
                if (slide.length < MAX_PARAGRAPH_SIZE) {
                    return [slide];
                }
                var statements = slide.match(/[^.!?]+[.!?]+/g);
                if (statements?.length <= 1 || !statements) {
                    return [slide];
                }
                const middle = Math.floor(statements.length / 2);
                const first = statements.slice(0, middle).join("").trim();
                const second = statements.slice(middle).join("").trim();
                return [first, second];
            })
            .map((slide) => ({ caption: slide }));
    }

    const generateStory = async () => {
        if (!user) {
            navigate("/signup");
            return;
        }
        ReactGA.event({
            category: "user",
            action: "Generate_Story",
        });
        requestPermission(user);
        setStoryLoading(true);
        setVideo(null);
        try {
            const response = await fetch(`${API}/story`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
                body: JSON.stringify({
                    prompt: description,
                    model,
                    temperature,
                    lang: lang?.substring(0, 2),
                    includeDesc: includeDesc || user?.plan,
                }),
            });
            const data = await response.json();
            if (response.status === 402) {
                swal(t("payment"), t("over"), "warning", {
                    buttons: {
                        cancel: {
                            visible: true,
                            text: t("buy"),
                        },
                        confirm: {
                            text: t("OK"),
                        },
                    },
                }).then(async (ok) => {
                    if (!ok) {
                        navigate("/buy");
                    }
                });
                return;
            }
            if (response.status === 400 || response.status === 500) {
                swal(t("error"), data.message, "error");
                return;
            }
            if (response.status === 401) {
                navigate("/signin");
                return;
            }
            if (!data.story) {
                return;
            }
            setGeneratePressed(true);
            if (includeDesc) {
                data.story = description + "\n\n" + data.story;
            }
            setScript(splitStoryIntoSlides(data.story));
            setTimeout(() => {
                if (autoFlow) {
                    picturesRef?.current?.click();
                }
            }, 500);
        } finally {
            setStoryLoading(false);
        }
    };

    const continueStory = async () => {
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }
        setContinueLoading(true);
        try {
            const response = await fetch(`${API}/story`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
                body: JSON.stringify({ prompt: script.slice(-1).pop().caption, model, temperature, includeDesc: true }),
            });
            const data = await response.json();
            if (response.status === 400 || response.status === 500) {
                swal(t("error"), data.message, "error");
                return;
            }
            if (!data.story) {
                return;
            }
            setGeneratePressed(true);
            const addition = splitStoryIntoSlides(data.story);
            setScript(script.concat(addition));
            setTimeout(() => {
                if (autoFlow) {
                    picturesRef?.current?.click();
                }
            }, 500);
        } finally {
            setContinueLoading(false);
        }
    };

    const generateImages = async () => {
        const newScript = [...script];
        const promises = [];
        for (let i = 0; i < newScript.length; i++) {
            const slide = newScript[i];
            if (!slide.image) {
                promises.push(
                    fetch(`${API}/image`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${user?.token}`,
                        },
                        body: JSON.stringify({
                            prompt: slide.caption,
                            lang: lang.substring(0, 2),
                            setting: setting + " style",
                            vertical,
                            imageModel: imageModels[imageModel],
                        }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            newScript[i].image = data?.url;
                        })
                );
            }
        }
        await Promise.all(promises);
        setScript(newScript);
    };

    const generateAudio = async () => {
        const newScript = [...script];
        const promises = [];
        for (let i = 0; i < newScript.length; i++) {
            const slide = newScript[i];
            if (!slide?.audio?.url) {
                promises.push(
                    fetch(`${API}/audio`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${user?.token}`,
                        },
                        body: JSON.stringify({
                            text: slide.caption,
                            lang: lang,
                            voice: voice,
                        }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            newScript[i].audio = data;
                        })
                );
            }
        }
        await Promise.all(promises);
        setScript(newScript);
    };

    const generateVideo = async () => {
        setVideoLoading(true);
        setVideoSuccess(false);
        try {
            await generateImages();
            await generateAudio();
            const response = await fetch(`${API}/video`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
                body: JSON.stringify({
                    script,
                    description,
                    lang,
                    voice,
                    setting,
                    vertical,
                    music,
                    effect,
                    user: userId,
                    _id: id || storyId,
                    forceNew: generatePressed,
                    noTitles: !titles,
                }),
            });
            const story = await response.json();
            if (response.status === 402) {
                swal(t("payment"), t("over"), "warning");
                return;
            }

            if (response.status === 400 || response.status === 500) {
                swal(t("error"), story.message, "error");
                return;
            }
            setVideo(story?.video);
            setStoryId(story?._id);
            setUserId(story?.user);
            setGeneratePressed(false);
            toast.success(t("ready"), {
                position: "bottom-left",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                onClick: () => {
                    navigate(`/account`);
                },
            });
        } finally {
            setVideoLoading(false);
            setVideoSuccess(true);
        }
    };

    const clickPictures = async () => {
        setPicturesLoading(true);
        setPicturesSuccess(false);
        try {
            await generateImages();
            await generateAudio();
            setTimeout(() => {
                if (autoFlow) {
                    videoRef?.current?.click();
                    scroll.scrollToBottom();
                }
            }, 1000);
        } finally {
            setPicturesLoading(false);
            setPicturesSuccess(true);
        }
    };

    const setSlideCaption = (index, caption) => {
        const newScript = [...script];
        newScript[index].caption = caption;
        newScript[index].audio = null;
        setScript(newScript);
    };

    const setSlideImage = (index, image) => {
        const newScript = [...script];
        newScript[index].image = image;
        setScript(newScript);
    };

    const repaintImage = async (index) => {
        setPicturesLoading(true);
        try {
            const response = await fetch(`${API}/image`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
                body: JSON.stringify({
                    prompt: script[index].caption,
                    lang: lang?.substring(0, 2),
                    setting: setting + " style",
                    vertical,
                    imageModel: imageModels[imageModel],
                }),
            });
            if (response.status === 402) {
                swal(t("payment"), t("over"), "warning");
                return;
            }
            const data = await response.json();
            if (response.status === 400 || response.status === 500) {
                swal(t("error"), data.message, "error");
                return;
            }
            const newScript = [...script];
            newScript[index].image = data.url;
            setScript(newScript);
        } finally {
            setPicturesLoading(false);
        }
    };

    const convertToVideo = async (index) => {
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }

        const ok = await swal({
            title: t("Stable Video Diffusion"),
            text: "Convert an image to 4 seconds video. This deducts 1 minute of your balance. Are you sure? ",
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
            dangerMode: true,
        });

        if (ok) {
            setPicturesLoading(true);
            try {
                const response = await fetch(`${API}/clip`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({
                        vertical,
                        image: API + script[index].image,
                    }),
                });
                if (response.status === 402) {
                    swal(t("payment"), t("over"), "warning");
                    return;
                }
                const data = await response.json();
                if (response.status === 400 || response.status === 500) {
                    swal(t("error"), data.message, "error");
                    return;
                }
                const newScript = [...script];
                newScript[index].image = data.url;
                setScript(newScript);
            } finally {
                setPicturesLoading(false);
            }
        }
    };

    const deleteSlide = (index) => {
        if (picturesLoading) {
            return;
        }
        swal({
            title: t("deleteSlide"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                setScript([...script].filter((_, i) => i !== index));
            }
        });
    };

    const listenAudio = (index) => {
        if (!script[index].audio) return;
        const audio = new Audio(API + script[index].audio.url);
        audio.play();
    };

    const selectLang = async (lang) => {
        const voice = voices.find((v) => v.lang === lang)?.voices?.[0];
        if (voice) {
            setVoice(voice);
        }
        if (script?.length > 0) {
            const newScript = [...script];
            for (let i = 0; i < newScript.length; i++) {
                newScript[i].audio = null;
            }
            setScript(newScript);
        }
        const response = await fetch(`${API}/translate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
            body: JSON.stringify({
                text: description,
                lang: lang.substring(0, 2),
            }),
        });
        const data = await response.json();
        if (response.status === 200 && data?.translation) {
            setDescription(data.translation);
            return;
        }
    };

    const structuredJson = () => {
        try {
            const seconds = script && script.reduce((acc, item) => acc + item.audio.duration, 0);
            return JSON.stringify({
                "@context": "https://schema.org",
                "@type": "VideoObject",
                name: description,
                description: script?.[0]?.caption,
                thumbnailUrl: [API + script?.[0]?.image],
                uploadDate: createdAt,
                duration: "PT" + Math.round(seconds) + "S",
                contentUrl: API + video,
            });
        } catch (e) {
            return "";
        }
    };

    const uploadYoutube = () => {
        swal({
            title: t("upload"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const response = await fetch(API + "/youtube/" + id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                });
                const data = await response.json();
                swal("Result", data.message, data?.message?.toLowerCase()?.includes("error") ? "error" : "success");
            }
        });
    };

    const uploadTikTok = () => {
        swal({
            title: t("upload_tiktok"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                const response = await fetch(API + "/tiktok/" + id, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                });
                const data = await response.json();
                swal("Result", data.message, data?.message?.toLowerCase()?.includes("error") ? "error" : "success");
            }
        });
    };

    const lockUser = () => {
        swal({
            title: "Lock User?",
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                const response = await fetch(API + "/user/" + userId, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ locked: true }),
                });
                if (response.status === 200) {
                    swal("Result", "User Locked", "success");
                }
            }
        });
    };

    const deleteStory = (id) => {
        swal({
            title: t("deleteStory"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                const result = await fetch(API + "/story", {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                    body: JSON.stringify({ id }),
                });
                if (result.status === 200) {
                    swal(t("done"), t("storyDeleted"), "success");
                }
            }
        });
    };

    const splitCell = (index) => {
        if (picturesLoading) {
            return;
        }
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }
        swal({
            title: t("split"),
            text: t("undone"),
            icon: "warning",
            buttons: {
                cancel: {
                    visible: true,
                    text: t("cancel"),
                },
                confirm: {
                    text: t("yes"),
                },
            },
        }).then(async (ok) => {
            if (ok) {
                setGeneratePressed(true);
                var statements = script[index].caption.match(/[^.!?]+[.!?]+/g);
                if (statements?.length > 1) {
                    const middle = Math.floor(statements.length / 2);
                    const first = statements.slice(0, middle).join("").trim();
                    const second = statements.slice(middle).join("").trim();
                    const newScript = [...script];
                    newScript[index].caption = first;
                    newScript[index].audio = null;
                    newScript.splice(index + 1, 0, { caption: second, image: script[index].image });
                    setScript(newScript);
                }
            }
        });
    };

    const downloadPdf = async () => {
        if (picturesLoading) {
            return;
        }
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }
        var doc = new jsPDF();
        doc.deletePage(1);
        doc.addFont(API + "/comicz.ttf", "ComicSans", "normal");
        doc.setFont("ComicSans");
        doc.setFontSize(25);
        script.forEach((slide, index) => {
            var splitTitle = doc.splitTextToSize(slide.caption, 180);
            doc.addPage("a4", "p");
            doc.addImage(
                `${API + slide.image}`,
                "JPEG",
                15,
                vertical ? 120 : 170,
                vertical ? 87.5 : 180,
                vertical ? 155 : 105
            );
            const MAX_VERTICAL = 8;
            if (vertical) {
                doc.text(splitTitle.slice(0, MAX_VERTICAL), 15, 35);
                if (splitTitle?.length > MAX_VERTICAL) {
                    const splitVertical = doc.splitTextToSize(splitTitle.slice(MAX_VERTICAL).join(" "), 90);
                    doc.text(splitVertical, 110, 117);
                }
            } else {
                doc.text(splitTitle, 15, 35);
            }
        });
        doc.addPage("a4", "p");
        doc.addImage(API + `/logo192.png`, "PNG", 80, 50, 50, 50);
        doc.save(`${setting}.pdf`);
    };

    const isPlan = () => {
        return user?.plan || user?.admin;
    };

    const isSilverPlus = () => {
        return user?.plan === "gold" || user?.plan === "silver" || user?.admin;
    };

    const pasteScript = async () => {
        if (!user) {
            navigate("/signup");
            return;
        }
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }
        const text = await navigator.clipboard.readText();
        if (!text) {
            return;
        }
        setVideo(null);
        setGeneratePressed(true);
        setScript(splitStoryIntoSlides(text));
        setTimeout(() => {
            if (autoFlow) {
                picturesRef?.current?.click();
            }
        }, 500);
    };

    return (
        <Container maxWidth="lg">
            <Helmet>
                <title>Manga TV - Creator</title>
                {video && <script type="application/ld+json">{structuredJson()}</script>}
            </Helmet>
            <Box sx={{ my: 4 }}>
                <Grid sx={{ my: 2 }} container spacing={2}>
                    <LangSelector
                        lang={lang}
                        onChange={(e) => {
                            setLang(e);
                            selectLang(e);
                        }}
                    />
                    <VoiceSelector
                        voice={voice}
                        onChange={setVoice}
                        lang={lang}
                        silver={isSilverPlus()}
                    />
                    <SettingSelector setting={setting} onChange={setSetting} plan={isPlan()} />
                    <MusicSelector music={music} onChange={setMusic} plan={isPlan()} />
                </Grid>
                <Grid sx={{ my: 2 }} container spacing={2}>
                    {isSilverPlus() && <TextModelSelector model={model} onChange={setModel} />}
                    <ImageModelSelector imageModel={imageModel} onChange={setImageModel} silver={isSilverPlus()} />
                    {isPlan() && <TemperatureSelector temperature={temperature} onChange={setTemperature} />}
                    <EffectSelector vertical={vertical} effect={effect} onChange={setEffect} />
                </Grid>
                <Grid sx={{ my: 2 }} container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={t("description")}
                            variant="outlined"
                            fullWidth
                            value={description}
                            placeholder={t("descriptionHint")}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid xs={12} md={12} item justifyContent="center">
                        <FormControlLabel
                            sx={{ mt: -2 }}
                            control={
                                <Switch checked={includeDesc} onChange={(e) => setIncludeDesc(e.target.checked)} />
                            }
                            label={t("include")}
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Button
                            disabled={storyLoading}
                            variant="contained"
                            color="primary"
                            onClick={() => generateStory()}
                        >
                            {t("generate")}
                        </Button>
                    </Grid>
                    <Grid xs={4} md={2} item justifyContent="center">
                        <ProgressButton
                            loading={picturesLoading}
                            innerRef={picturesRef}
                            onClick={clickPictures}
                            title={t("pictures")}
                            success={picturesSuccess}
                            disabled={!user || picturesLoading || storyLoading || !script?.length > 0}
                        />
                    </Grid>
                    <Switches
                        autoFlow={autoFlow}
                        setAutoFlow={setAutoFlow}
                        titles={titles}
                        setTitles={setTitles}
                        vertical={vertical}
                        setVertical={(e) => {
                            setVertical(e);
                            setEffect("");
                        }}
                        verticalDisabled={!!id || generatePressed}
                    />
                    <Grid item xs={6} md={3}>
                        <Button
                            disabled={storyLoading || picturesLoading || script.length > 0}
                            variant="outlined"
                            color="secondary"
                            onClick={() => pasteScript()}
                        >
                            {t("Paste script")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid sx={{ my: 2 }} container spacing={2}>
                    {storyLoading ? (
                        <Grid container sx={{ mx: 2 }} justifyContent="center">
                            <Loading />
                            <Grid xs={1} md={1} item justifyContent="center">
                                <InfoIcon color="primary" />
                            </Grid>
                            <Grid xs={11} md={11} item justifyContent="left">
                                <Typography variant="body2">{t("hint2")}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        script?.length > 0 && (
                            <>
                                <Slides
                                    script={script}
                                    vertical={vertical}
                                    splitCell={splitCell}
                                    setSlideCaption={setSlideCaption}
                                    setSlideImage={setSlideImage}
                                    repaintImage={repaintImage}
                                    convertToVideo={convertToVideo}
                                    deleteSlide={deleteSlide}
                                    listenAudio={listenAudio}
                                />
                                <ProgressButton
                                    loading={continueLoading}
                                    innerRef={continueRef}
                                    onClick={continueStory}
                                    title={t("continue")}
                                    disabled={!user || videoLoading}
                                />
                                <ProgressButton
                                    sx={{ my: 5 }}
                                    loading={videoLoading}
                                    innerRef={videoRef}
                                    onClick={generateVideo}
                                    title={t("video")}
                                    success={videoSuccess}
                                    disabled={!user || videoLoading}
                                />
                                {videoLoading && (
                                    <Grid container justifyContent="center">
                                        <InfoIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="body2">{t("hint")}</Typography>
                                    </Grid>
                                )}
                                <Grid sx={{ my: 1 }} container justifyContent="center">
                                    <Video vertical={vertical} key={video} src={API + video} />
                                </Grid>
                                {video && (
                                    <Grid container justifyContent="center">
                                        <Box>
                                            <Share setting={setting} description={description} url={API + video} />
                                            <IconButton disabled={!id} onClick={downloadPdf}>
                                                <PictureAsPdfIcon />
                                            </IconButton>
                                            {user?.admin && (
                                                <>
                                                    <IconButton disabled={!id} onClick={uploadYoutube}>
                                                        <YouTubeIcon />
                                                    </IconButton>
                                                    <IconButton disabled={!id} onClick={uploadTikTok}>
                                                        <TikTokIcon />
                                                    </IconButton>{" "}
                                                    <IconButton onClick={lockUser}>
                                                        <ReportIcon color="primary" />
                                                    </IconButton>
                                                    <IconButton onClick={() => deleteStory(id)}>
                                                        <RemoveCircleOutline color="primary" />
                                                    </IconButton>
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                )}
                            </>
                        )
                    )}
                </Grid>
            </Box>
        </Container>
    );
};

export default Creator;
