import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { AppBar as MuiAppBar, Avatar, Button, IconButton, Menu, MenuItem, Badge } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Search } from "@mui/icons-material";
import { API, UserContext } from "../App";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { googleLogout } from "@react-oauth/google";
import { pink } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const cookies = new Cookies();

const AppBar = ({ onDrawer }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [newCount, setNewCount] = useState(0);
    const navigate = useNavigate();
    const user = useContext(UserContext);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openAccount = () => {
        handleClose();
        navigate("/account");
    };

    const logout = () => {
        handleClose();
        cookies.remove("TOKEN", { path: "/" });
        googleLogout();
        window.location.href = "/";
    };

    const handleDrawer = () => {
        onDrawer(true);
    };

    useEffect(() => {
        try {
            const last = window?.localStorage?.getItem("lastStory");
            if (user?.admin) {
                fetch(API + "/stories/plain")
                    .then((response) => response.json())
                    .then((data) => {
                        data = data.filter((s) => s.createdAt > last || !last);
                        setNewCount(data.length);
                    });
            }
        } catch {}
    }, [user]);

    const handleNotifyOpen = () => {
        if (newCount > 0) {
            navigate(`/stories/plain/${newCount}`);
        }
    };

    return (
        <MuiAppBar color="secondary" position="sticky">
            <Toolbar sx={{ flexWrap: "wrap" }}>
                <IconButton
                    onClick={handleDrawer}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography noWrap variant="h6" sx={{ flexGrow: 1 }}>
                    Manga TV
                </Typography>
                <IconButton size="large" component={Link} to="/search" color="inherit">
                    <Search />
                </IconButton>
                {user?.admin && newCount > 0 && (
                    <IconButton onClick={handleNotifyOpen} color="inherit">
                        <Badge badgeContent={newCount} color="primary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                )}
                {user && (
                    <div>
                        <IconButton size="large" onClick={handleMenu} color="inherit">
                            {user?.profileUrl ? (
                                <Avatar src={user?.profileUrl} />
                            ) : (
                                <Avatar sx={{ bgcolor: pink[500] }}> {user?.firstName?.[0]} </Avatar>
                            )}
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={openAccount}>{t("account")}</MenuItem>
                            <MenuItem onClick={logout}>{t("logout")}</MenuItem>
                        </Menu>
                    </div>
                )}
                {!user && (
                    <Button component={Link} to="/signin" color="inherit">
                        {t("signin")}
                    </Button>
                )}
            </Toolbar>
        </MuiAppBar>
    );
};

export default AppBar;
