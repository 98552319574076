import Avatar from "@mui/material/Avatar";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../UI/Copyright";
import { API } from "./../App";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
const cookies = new Cookies();

const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

const SignUp = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { referrer } = useParams();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        submitData(data);
    };

    const googleLogin = (response) => {
        const data = new FormData();
        data.set("referrer", document.getElementById("referrer")?.value);
        data.append("credential", response.credential);
        submitData(data);
    };

    const submitData = async (data) => {
        setLoading(true);
        setError("");
        ReactGA.event({
            category: "user",
            action: "SignUp",
        });
        try {
            if (data.get("password") !== data.get("verifyPassword")) {
                setError("Password does not match 😫");
                return;
            }
            if (!data.get("credential") && !validateEmail(data.get("email"))) {
                setError("Invalid email address 😫");
                return;
            }
            const response = await fetch(`${API}/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstName: data.get("firstName"),
                    lastName: data.get("lastName"),
                    email: data.get("email")?.toLowerCase(),
                    password: data.get("password"),
                    referrer: data.get("referrer")?.toUpperCase(),
                    credential: data.get("credential"),
                }),
            });
            const json = await response.json();
            if (!response.ok) {
                setError(json.message);
                return;
            }
            if (json.token) {
                cookies.set("TOKEN", json.token, {
                    path: "/",
                    maxAge: 30 * 24 * 3600,
                });
                window.location.href = "/story";
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Grid container justifyContent="center">
                    <Avatar sx={{ bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography sx={{ m: 1 }} component="h1" variant="h5">
                        {t("signup")}
                    </Typography>
                </Grid>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label={t("first")}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label={t("last")}
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label={t("email")}
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label={t("password")}
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="verifyPassword"
                                label={t("retype")}
                                type="password"
                                id="password2"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="referrer"
                                label={t("referral")}
                                id="referrer"
                                defaultValue={referrer}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        {loading && <CircularProgress color="primary" />}
                    </Grid>
                    {error && (
                        <Typography color="error" variant="body2">
                            {error}
                        </Typography>
                    )}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        {t("signup")}
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link component={RouterLink} to="/signin" variant="body2">
                                {t("already")}
                            </Link>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid container justifyContent="center">
                        <GoogleLogin theme="filled_black" size="large" onSuccess={googleLogin} />
                    </Grid>
                </Box>
            </Box>
            <Copyright />
        </Container>
    );
};

export default SignUp;
