import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { API, UserContext } from "../App";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

export default function ImageUpload({ onUpload, vertical }) {
    const [imageError, setImageError] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useContext(UserContext);
    const { t } = useTranslation();

    const imageHandler = async (image) => {
        if (!user?.plan && !user?.admin) {
            swal("", t("paid"), "warning");
            return;
        }
        let formData = new FormData();
        formData.append("data", JSON.stringify({ vertical }));
        formData.append("image", image);

        if (image?.size > 15000000) {
            setImageError(true);
            return;
        }

        setLoading(true);
        const response = await fetch(API + "/image/upload", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${user?.token}`,
            },
            body: formData,
        });
        setLoading(false);
        if (response.ok) {
            const data = await response.json();
            setImageError(false);
            onUpload(data.image);
        } else {
            setImageError(true);
        }
    };

    return (
        <>
            <Button startIcon={<AddAPhotoIcon />} variant="outlined" color="secondary" component="label">
                <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => {
                        imageHandler(event.target.files[0]);
                    }}
                />
                {loading ? <CircularProgress style={{ position: "absolute" }} size={20} /> : null}
            </Button>
            {imageError && (
                <Typography sx={{ mt: 1 }} variant="body2">
                    {"Please PNG or JPEG or MP4, max 15MB"}
                </Typography>
            )}
        </>
    );
}
