import { useContext } from "react";
import NavigationItem from "../Layout/NavigationItem";
import List from "@mui/material/List";
import HomeIcon from "@mui/icons-material/Home";
import ShopIcon from "@mui/icons-material/Shop";
import SearchIcon from "@mui/icons-material/Search";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TelegramIcon from "@mui/icons-material/Telegram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import { UserContext } from "../App";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import QuestionMark from "@mui/icons-material/QuestionMark";
import AndroidIcon from "@mui/icons-material/Android";
import LogoDevIcon from "@mui/icons-material/LogoDev";
import LandscapeIcon from "@mui/icons-material/Landscape";
import AudioFileIcon from "@mui/icons-material/SpatialAudio";
import InfoIcon from "@mui/icons-material/Info";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

const NavigationItems = () => {
    const { t } = useTranslation();
    const user = useContext(UserContext);

    return (
        <List>
            <NavigationItem icon={<HomeIcon />} link="/">
                {t("home")}
            </NavigationItem>
            <NavigationItem icon={<StarIcon />} link="/stories/featured">
                {t("featured")}
            </NavigationItem>
            <NavigationItem icon={<SearchIcon />} link="/search">
                {t("search")}
            </NavigationItem>
            <NavigationItem icon={<LandscapeIcon />} link="/custom">
                {t("custom")}
            </NavigationItem>
            {user?.admin && (
                <NavigationItem icon={<AudioFileIcon />} link="/voices">
                    {t("myVoices")}
                </NavigationItem>
            )}
            <NavigationItem icon={<ShopIcon />} link="/buy">
                {t("buy")}
            </NavigationItem>
            <Divider />
            <NavigationItem icon={<TelegramIcon />} link="https://t.me/maxsoft_chat_gpt_group">
                Telegram
            </NavigationItem>
            <NavigationItem icon={<YoutubeIcon />} link="https://www.youtube.com/@MangaTVShop">
                YouTube
            </NavigationItem>

            <NavigationItem
                icon={<AndroidIcon />}
                link="https://play.google.com/store/apps/details?id=shop.mangatv.twa"
            >
                Play Store
            </NavigationItem>
            <Divider />
            <NavigationItem icon={<InfoIcon />} hard link="/landing/index.html">
                About Us
            </NavigationItem>
            <NavigationItem icon={<QuestionMark />} hard link="/faq">
                FAQ
            </NavigationItem>
            <NavigationItem icon={<LogoDevIcon />} link="https://blog.mangatv.shop/category/manga-tv/">
                What's New
            </NavigationItem>
            <NavigationItem icon={<ThumbUpAltIcon />} link="/feedback">
                Feedback
            </NavigationItem>
            {user?.admin && (
                <>
                    <Divider />
                    <NavigationItem icon={<LeaderboardIcon />} link="/dashboard">
                        {t("dashboard")}
                    </NavigationItem>
                </>
            )}
        </List>
    );
};

export default NavigationItems;
